// Libraries
import {applyMiddleware, axios, createStore, thunk} from "../assets/fileImports/Libraries";
// Action
import {setLocationFilterData, setPagesData} from "../action";
// Reducer
import rootReducer from "../reducer";
// Config file
import config from "../config.json"

const middleware = applyMiddleware(thunk);
export const store = createStore(rootReducer, middleware);

store.dispatch(dispatch => {
    axios
        .all([
            axios.get(config.apiUrl + "filters"),
            axios.get(config.apiUrl + "locations"),
            axios.get(config.apiUrl + "pages")
        ], {headers: {"Cache-Control": "no-cache"}})
        .then(
            axios.spread((filters, locations, pages) => {
                // Filters
                // Location
                dispatch(setLocationFilterData(locations.data, filters.data));

                // Pages
                dispatch(setPagesData(pages.data));
            })
        );
});

