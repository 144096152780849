// Libreries
import {connect, React, ReactHtmlParser, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Box, Button, CardContent, PropTypes, withWidth} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
import "../../assets/css/infoBox.css";
// Action
import {filterLocationList, resetFilter, setSelectedFilters, toggleInfoBox} from "../../action";
// Style
import {infoBoxStyle} from "./Style"

function getPresetFilter(filterName) {
    let selectedOffers = [];
    switch (filterName) {
        case "globalFilterButtonKeineWohnung":
            selectedOffers = [
                {name: "WUW", id: 3},
                {name: "BEW", id: 1},
                {name: "BGW", id: 2},
                {name: "ÜGH", id: 4}
            ];
            break;
        case "globalFilterButtonImminent":
            selectedOffers = [
                {name: "WUW", id: 3},
                {name: "BEW", id: 1}
            ];
            break;
        case "globalFilterButtonCrisis":
            selectedOffers = [{name: "KRI", id: 5}];
            break;
        default:
            selectedOffers = [];
            break;
    }

    return {
        offers: selectedOffers,
        features: [{name: "filterTypeAll", id: 0}],
        genders: [{name: "filterTypeAll", id: 0}],
        languages: [{name: "filterTypeAll", id: 0}]
    };
}

function InfoBox(props) {
    const {t, width} = props;

    const classes = infoBoxStyle();

    function handleClick(filterName) {
        let presetFilter = getPresetFilter(filterName);
        props.filterLocationList(presetFilter, filterName);
    }

    function handleResetFilter() {
        if (props.isFilterSet) {
            props.resetFilter();
        }
    }

    return (
        <CardContent className={classes.cardContentBG} style={props.presetFilterName === "" ? {marginTop: "0"} : null}>

            {/* no filter selected */}
            {props.presetFilterName === "" &&
            <Box className={classes.fontSizeRegular}> {ReactHtmlParser(t("globalFilterNoneDescription"))}</Box>}

            {/* Offers detail */}
            {props.presetFilterName === "globalFilterButtonKeineWohnung" &&
            <Box className={classes.fontSizeRegular}> {ReactHtmlParser(t("globalFilterImminentDescription"))}</Box>}
            {props.presetFilterName === "globalFilterButtonImminent" &&
            <Box className={classes.fontSizeRegular}> {ReactHtmlParser(t("globalFilterKeineWohnungDescription"))}</Box>}
            {props.presetFilterName === "globalFilterButtonCrisis" &&
            <Box className={classes.fontSizeRegular}> {ReactHtmlParser(t("globalFilterCrisisDescription"))}</Box>}

            {/* Preset filter buttons */}
            {props.presetFilterName !== "globalFilterButtonImminent" && getFilterButtons(classes, t, "globalFilterButtonImminent", () => {
                handleClick("globalFilterButtonImminent")
            }, width)}
            {props.presetFilterName !== "globalFilterButtonKeineWohnung" && getFilterButtons(classes, t, "globalFilterButtonKeineWohnung", () => {
                handleClick("globalFilterButtonKeineWohnung")
            }, width)}
            {props.presetFilterName !== "globalFilterButtonCrisis" && getFilterButtons(classes, t, "globalFilterButtonCrisis", () => {
                handleClick("globalFilterButtonCrisis")
            }, width)}

            {/* Reset button */}
            {props.presetFilterName !== "" && getFilterButtons(classes, t, "globalFilterReset", () => {
                handleResetFilter()
            }, width, "text")}

        </CardContent>
    );
}

function getFilterButtons(classes, t, name, onclick, width, btnType = "outlined") {
    return <Button
        variant={btnType}
        fullWidth
        onClick={onclick}
        className={
            width === "xs" || width === "sm"
                ? classes.mobileButtonTextSize
                : classes.buttonStyle
        }
    >
        {t(name)}
    </Button>
}

const mapStateToProps = state => {
    return {
        isFilterSet: state.filter.isFilterApplied,
        presetFilterName: state.filter.presetFilterName
    };
};

const mapDispatchToProps = dispatch => ({
    setSelectedFilters: filter => dispatch(setSelectedFilters(filter)),
    filterLocationList: (filter, presetFilterName) => dispatch(filterLocationList(filter, presetFilterName)),
    toggleInfoBox: () => dispatch(toggleInfoBox()),
    resetFilter: () => dispatch(resetFilter())
});

InfoBox.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(InfoBox)));
