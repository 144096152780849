// Libreries
import {connect, React, ReactHtmlParser, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography
} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
import "../../assets/css/welcomeContent.css"
// Actions
import {closeTheWelcomeFilter, filterLocationList} from "../../action";
// style
import {welcomeContentStyle} from "./Style"

function WelcomeConent(props) {
    const {t} = props;
    const classes = welcomeContentStyle();

    function getPresetFilter(filterName) {
        let selectedOffers = [];
        switch (filterName) {
            case "globalFilterButtonKeineWohnung":
                selectedOffers = [
                    {name: "WUW", id: 3},
                    {name: "BEW", id: 1},
                    {name: "BGW", id: 2},
                    {name: "ÜGH", id: 4}
                ];
                break;
            case "globalFilterButtonImminent":
                selectedOffers = [
                    {name: "WUW", id: 3},
                    {name: "BEW", id: 1}
                ];
                break;
            case "globalFilterButtonCrisis":
                selectedOffers = [{name: "KRI", id: 5}];
                break;
            default:
                selectedOffers = [];
                break;
        }

        return {
            offers: selectedOffers,
            features: [{name: "filterTypeAll", id: 0}],
            genders: [{name: "filterTypeAll", id: 0}],
            languages: [{name: "filterTypeAll", id: 0}]
        };
    }

    function handleClick(filterName) {
        let presetFilter = getPresetFilter(filterName);
        localStorage.setItem("showWelcomeFilter", false);
        props.filterLocationList(presetFilter, filterName);
    }

    const handleClose = () => {
        localStorage.setItem("showWelcomeFilter", false);
        props.closeTheWelcomeFilter();
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.firstTimeWebOpenedVar}
            >
                <DialogContent dividers style={{paddingBottom: "0"}}>
                    <Typography
                        variant="h5"
                        comonent="h5"
                        className={classes.fontSizeForHeader}
                    >
                        {t("onboardingPopupTitle")}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        comonent="subtitle1"
                        className={classes.welcomeSelectFilterSubTitle}>
                        {t("onboardingPopupDescription")}
                    </Typography>
                    <Grid container spacing={3}>
                        {getFilterButtons(classes, t, "globalFilterButtonKeineWohnung", "onboardingPopupKeineWohnungDescription", () => {
                            handleClick("globalFilterButtonKeineWohnung")
                        })}
                        {getFilterButtons(classes, t, "globalFilterButtonImminent", "onboardingPopupImminentDescription", () => {
                            handleClick("globalFilterButtonImminent")
                        })}
                        {getFilterButtons(classes, t, "globalFilterButtonCrisis", "onboardingPopupCrisisDescription", () => {
                            handleClick("globalFilterButtonCrisis")
                        })}
                    </Grid>
                    <Box className={classes.paragraphUrl}>
                        {ReactHtmlParser(t("onboardingPopupLink"))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.skipButton}
                    >
                        {t("onboardingPopupButtonSkip")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function getFilterButtons(classes, t, title, detail, onclick) {
    return <Grid item xs={12}>
        <Paper
            className={(classes.paper + " paperHover")}
            onClick={onclick}
        >
            <Typography
                variant="h6"
                comonent="h6"
                className={classes.welcomeButtonsTitle}
            >
                {t(title)}
            </Typography>
            <Typography className={classes.welcomeButtonsDesc}>
                {t(detail)}
            </Typography>
        </Paper>
    </Grid>
}

const mapStateToProps = state => {
    return {
        firstTimeWebOpenedVar:
            localStorage.getItem("showWelcomeFilter") === "false"
                ? false
                : state.filter.firstTimeWebOpenedVar
    };
};

const mapDispatchToProps = dispatch => ({
    closeTheWelcomeFilter: () => dispatch(closeTheWelcomeFilter()),
    filterLocationList: (filter, presetFilterName) =>
        dispatch(filterLocationList(filter, presetFilterName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(WelcomeConent));
