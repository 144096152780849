// Libreries
import {connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Components
import {Paper, Tab, Tabs} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
// Styles
import {mobileTabStyles} from "./Style"
// Action
import {changeMainTab} from "../../action";

function MobileTab(props) {
    const classes = mobileTabStyles();
    const {t} = props;

    return (
        <div>
            <Paper className={classes.root}>
                <Tabs
                    value={
                        props.mainTabIndex === 0 || props.mainTabIndex === 1
                            ? props.mainTabIndex
                            : 0
                    }
                    TabIndicatorProps={{style: {background: "rgb(250, 250, 250)"}}}
                    className={classes.fullWidthTab}
                    centered
                >
                    {getTabs(classes, 0, t("locationListTab"), props)}
                    {getTabs(classes, 1, t("locationMapTab"), props)}
                </Tabs>
            </Paper>
        </div>
    );
}

function getTabs(classes, index, tabTitle, props) {
    return (
        <Tab
            label={tabTitle}
            className={
                props.mainTabIndex === index
                    ? classes.selectedTab
                    : classes.unSelectedTab
            }
            onClick={() => {
                props.changeMainTab(index);
            }}
        />
    );
}

const mapStateToProps = state => {
    return {
        mainTabIndex: state.ui.tabIndex,
        showFilterDialogue: state.filter.showFilterDialogue
    };
};

const mapDispatchToProps = dispatch => ({
    changeMainTab: id => dispatch(changeMainTab(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(MobileTab));
