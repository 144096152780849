import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// Libraries
import {
  CookiesProvider,
  i18next,
  I18nextProvider,
  Provider,
  React,
  ReactDOM,
  Router
} from "./assets/fileImports/Libraries";
// Component
import {AppRouter, BrowserHistory} from "./assets/fileImports/Components";
// CSS
import "./assets/css/index.css";

import * as serviceWorker from "./serviceWorker";
import {store} from "./store/store";
// Language file
import lang_de from "./translations/de/lang.json";
import lang_en from "./translations/en/lang.json";
// Config file
import config from "./config.json"

i18next.init({
    interpolation: {escapeValue: false}, // React already does escaping
    lng: config.language, // language to use
    resources: {
        en: {
            common: lang_en // 'common' is our custom namespace
        },
        de: {
            common: lang_de
        }
    }
});

ReactDOM.render(
    <Router history={BrowserHistory}>
        <I18nextProvider i18n={i18next}>
            <CookiesProvider>
                <Provider store={store}>
                    <AppRouter/>
                </Provider>
            </CookiesProvider>
        </I18nextProvider>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
