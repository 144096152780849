// Libreries
import {connect, DebounceInput, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {
    CancelIcon,
    Grid,
    ListItem,
    ListItemIcon,
    Paper,
    PropTypes,
    withWidth
} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
import "../../assets/css/searchBar.css"
// Images
import images from "../../assets/fileImports/Images"
// Action
import {searchInLocationList} from "../../action";
// Style
import {facilitiesHeaderStyle} from "./Style"

function SearchTab(props) {
    const classes = facilitiesHeaderStyle();
    const {t, width} = props;

    if (width === "xs" || width === "sm") {
        return null
    }

    return (
        <Grid item xs={4} sm={4} md={4} lg={4}>
            <Paper className={classes.searchTab}>
                <div className={classes.searchTabCntainer}>
                    <ListItem className={classes.nested}>
                        <ListItemIcon className={classes.searchIcon}>
                            <img src={images["search_icon"]} alt=""/>
                        </ListItemIcon>

                        <DebounceInput
                            name="searchInList"
                            type="search"
                            value={props.searchedText}
                            minLength={0}
                            debounceTimeout={500}
                            onChange={e => {
                                props.searchInLocationList(e.target.value);
                            }}
                            placeholder={t("searchPlaceholder")}
                            className={(classes.SearchTabClass)}
                        />
                        {props.searchedText === "" ? null : (
                            <CancelIcon className={classes.cancelIcon}
                                        onClick={() => {
                                            props.searchInLocationList("");
                                        }}
                            />
                        )}
                    </ListItem>
                </div>
            </Paper>
        </Grid>
    );
}

SearchTab.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        searchedText: state.filter.searchedText
    };
};

const mapDispatchToProps = dispatch => ({
    searchInLocationList: searchText => dispatch(searchInLocationList(searchText))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(SearchTab)));
