// Libreries
import {connect, React} from "../../assets/fileImports/Libraries"
// Material UI
import {
  AppBar,
  CancelIcon,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  MenuIcon,
  Paper,
  Toolbar,
  Typography
} from "../../assets/fileImports/MaterialUI"
// Created components
import {FacilitiesHeader, MobileTab} from "../../assets/fileImports/Components"
// font css
import "../../assets/css/font.css";
import "../../assets/css/searchBar.css";
// Language translation
import {withTranslation} from "react-i18next";
// Images
import images from "../../assets/fileImports/Images"
// Style
import {headerStyle} from "./Style"
// Action
import {
  changeMainTab,
  closeSearchForInput,
  expandSearchForInput,
  searchInLocationList,
  toggleDrawer,
  toggleFilterDialogue
} from "../../action";

function MobileHeader(props) {
    const {t} = props;
    const classes = headerStyle();

    return (
        <AppBar position="fixed" className={classes.appbar}>
            <Toolbar className={classes.mobileHeader}>
                <Grid container spacing={0}>
                    {props.expandSearch
                        ? <Grid item xs={12} sm={12}>
                            <Grid container spacing={0} wrap="nowrap">
                                <Hidden mdUp>
                                    {getExpandedSearch(props, classes, t)}
                                </Hidden>
                            </Grid>
                        </Grid>
                        : <Grid item xs={12} sm={12}>
                            <Grid container spacing={0} wrap="nowrap">
                                <Grid item xs={8}>
                                    <Grid container spacing={0} wrap="nowrap">
                                        <Hidden mdUp>
                                            {getIconButton({
                                                icon: <MenuIcon/>,
                                                position: "start",
                                                showRedDot: false,
                                                onclick: props.toggleDrawer
                                            })}
                                        </Hidden>
                                        {getHeading({
                                            t: t,
                                            classes: classes,
                                            title: "appbar_heading",
                                            onClick: () => {
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Hidden mdUp>
                                        {!props.expandSearch && getHeaderOptions(props, classes)}
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>

            </Toolbar>
            <Paper square className={classes.mobileTabContaner}>
                <MobileTab/>
            </Paper>
            {
                props.mainTabIndex === 0
                    ? <FacilitiesHeader/>
                    : null
            }
        </AppBar>
    );
}

function getExpandedSearch(props, classes, t) {
    return (<div className={classes.searchOpened}>
        <IconButton onClick={props.closeSearchForInput}>
            <img src={images["back_icon"]} alt=""/>
        </IconButton>
        <InputBase
            name="searchInList"
            type={"search"}
            value={props.searchedText}
            onChange={e => {
                props.searchInLocationList(e.target.value)
            }}
            className={classes.inputInputExpand}
            autoFocus={true}
            placeholder={t("searchPlaceholder")}
            inputProps={{"aria-label": "searchInList"}}
        />

        {props.searchedText !== ""
        && <IconButton onClick={() => {
            props.searchInLocationList("")
        }}>
            <CancelIcon className={classes.cancelIcon}/>
        </IconButton>}
    </div>)
}

function getHeaderOptions(props, classes) {

    return (
        <div className={classes.iconWrapper}>
            {getIconButton({
                classes: classes,
                iconName: "search_image",
                position: false,
                showRedDot: !props.isSearchTextEmpty,
                redDotClass: classes.redDotSearch,
                onclick: props.expandSearchForInput
            })}

            {getIconButton({
                classes: classes,
                iconName: "filter_image",
                position: "end",
                showRedDot: props.isFilterApplied,
                redDotClass: classes.redDotFilter,
                onclick: props.toggleFilterDialogue
            })}
        </div>
    )
}

function getIconButton({classes, icon, iconName, position, showRedDot, redDotClass, onclick}) {

    return (<IconButton
        edge={position}
        onClick={onclick}
        color="inherit"
    >
        {icon ? icon : <img src={images[iconName]} alt="" className={classes.iconSize}/>}
        {showRedDot && <div className={redDotClass}/>}
    </IconButton>)
}

function getHeading({t, classes, title, onClick}) {
    return (
        <Typography
            className={classes.title}
            onClick={onClick}
            noWrap
        >
            {t(title)}
        </Typography>
    )
}

const mapStateToProps = state => {
    return {
        mainTabIndex: state.ui.tabIndex,
        expandSearch: state.ui.expandSearchForInput,
        searchedText: state.filter.searchedText,
        isFilterApplied: state.filter.isFilterApplied,
        isSearchTextEmpty: state.filter.searchedText === "",
        showInfoBox: state.ui.showInfoBox,
    };
};

const mapDispatchToProps = dispatch => ({
    changeMainTab: id => dispatch(changeMainTab(id)),
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleFilterDialogue: () => dispatch(toggleFilterDialogue()),
    expandSearchForInput: () => dispatch(expandSearchForInput()),
    closeSearchForInput: () => dispatch(closeSearchForInput()),
    searchInLocationList: searchText => dispatch(searchInLocationList(searchText))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(MobileHeader));
