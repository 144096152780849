// Libreries
import {connect, React, useHistory} from "../../assets/fileImports/Libraries"
// Material UI
import {
  AppBar,
  ArrowBackOutlinedIcon,
  Grid,
  IconButton,
  PropTypes,
  Toolbar,
  Typography,
  withWidth
} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
// Language translation
import {withTranslation} from "react-i18next";
// Style
import {headerStyle} from "./Style"
// Action
import {changeMainTab, closeLocationDetail} from "../../action";

function SimpleHeader(props) {
    const {t, width} = props;
    const classes = headerStyle();
    let history = useHistory();

    return (
        <AppBar position="fixed" className={classes.appbar}>
            <Toolbar className={(width === "sm" || width === "xs" ? classes.mobileHeader : classes.webHeader)}>
                <Grid item>
                    <Grid container spacing={0} wrap="nowrap">
                        {props.enableBackBTN && getIconButton(props, classes, history)}
                        {getHeading({
                            t: t,
                            classes: classes,
                            title: props.title,
                        })}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

function getIconButton(props, classes, history) {
    return (<IconButton
        edge="start"
        className={classes.backButton}
        style={{cursor: "pointer"}}
        color="inherit"
        aria-label="menu"
        onClick={() => {
            if (props.title === "location_detail_appbar_heading") {
                props.closeLocationDetail();
            }
            history.replace("/");
        }}
    >
        <ArrowBackOutlinedIcon/>
    </IconButton>)
}

function getHeading({t, classes, title}) {
    return (
        <Typography className={classes.title} noWrap>
            {t(title)}
        </Typography>
    )
}

SimpleHeader.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        mainTabIndex: state.ui.tabIndex,
        locationDetailIdFromURL: state.filter.locationDetailIdFromURL
    };
};

const mapDispatchToProps = dispatch => ({
    changeMainTab: id => dispatch(changeMainTab(id)),
    closeLocationDetail: () => dispatch(closeLocationDetail())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(SimpleHeader)));
