// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const footerStyle = makeStyles(() => ({
    title: {
        textAlign: "left"
    },
    appbar: {
        backgroundColor: "#273a66"
    },
    container: {
        backgroundColor: "#f1f1f1",
        minHeight: "35px"
    },
    footerLabels: {
        fontFamily: "roboto-regular",
        color: "rgba(0,0,0,0.6)",
        backgroundColor: "transparent",
        transition: "none 0s ease 0s",
        margin: "0 20px",
        padding: "8px 0",
        fontSize: "15px",
        cursor: "pointer"
    },
}));

export {footerStyle}
