// Libreries
import {connect, React, useHistory, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Drawer, List, ListItem, ListItemText} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
// Images
import images from "../../assets/fileImports/Images"
// Actions
import {changeMainTab, toggleDrawer} from "../../action";
// Style
import {leftDrawerStyle} from "./Style"

function LeftDrawer(props) {
    const classes = leftDrawerStyle();
    const {t} = props;
    let history = useHistory();

    function getDrawerRows() {
        let drawer = [];
        for (const slug in props.pages) {
            if (props.pages.hasOwnProperty(slug)) {
                const page = props.pages[slug];
                if (page.title)
                    drawer.push(getDrawerOptions(slug, page.title))
            }
        }
        return drawer
    }

    function getDrawerOptions(slug, text) {
        return (
            <ListItem button key={text}>
                <ListItemText
                    className={classes.typoCapitalize}
                    primary={t(text)}
                    onClick={() => {
                        if (history.location.pathname.indexOf("page") !== -1) {
                            history.replace("/" + slug)
                        } else {
                            history.push("/" + slug);
                        }
                    }}
                />
            </ListItem>
        );
    }

    return (
        <div>
            <Drawer open={props.toggleDrawerLeft} onClose={props.toggleDrawer}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={props.toggleDrawer}
                    onKeyDown={props.toggleDrawer}
                >
                    <div className={classes.logoSpace}>
                        <img src={images['banner-app']} alt="banner-app"/>
                    </div>
                    <List>
                        {getDrawerRows()}
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        toggleDrawerLeft: state.ui.toggleDrawerLeft,
        pages: state.ui.pagesData
    };
};

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    changeMainTab: id => dispatch(changeMainTab(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(LeftDrawer));
