// Libraries
import {connect, React, Route} from "../assets/fileImports/Libraries"
// Material UI
import {PropTypes, withWidth} from "../assets/fileImports/MaterialUI"
// Components
import {App, LocationDetailPage, StaticPage,} from "../assets/fileImports/Components"

// Action
// import { setCurrentLocation } from "../action/index";

function AppRouter(props) {

    const {width} = props;
    let isDesktop = width !== "sm" && width !== "xs";

    return (
        <Route>
            <Route exact path="/" component={App}/>
            <Route path="/:slug" component={StaticPage}/>
            <Route path="/locationDetail/:id" component={isDesktop ? App : LocationDetailPage}/>
        </Route>
    )

    // return (
    //     < HashRouter>
    //         <Route exact path="/" component={App} />
    //         <Route path="/static/:id" component={StaticPage} />
    //         <Route path="/locationDetail/:id" component={isDesktop ? App : LocationDetailPage} />
    //     </ HashRouter>
    // )

}

AppRouter.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    // setCurrentLocation: (currentLocation) => dispatch(setCurrentLocation(currentLocation))
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(AppRouter));
