// Action
import {actionType} from "../action";

const initialState = {
    tabIndex: 0,
    showFilterDialogue: false,
    searchedText: "",
    showInfoBox: false,
    pagesData: {},
    toggleDrawerLeft: false,
    expandSearchForInput: false
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.changeMainTab:
            return {
                ...state,
                tabIndex: action.index,
            };
        case actionType.toggleInfoBox:
            return {
                ...state,
                showInfoBox: !state.showInfoBox
            };
        case actionType.setPagesData:
            return {
                ...state,
                pagesData: setStaticPagesObject(action.data)
            };
        case actionType.toggleDrawer:
            return {
                ...state,
                toggleDrawerLeft: !state.toggleDrawerLeft
            };
        case actionType.expandSearchForInput:
            return {
                ...state,
                expandSearchForInput: true
            };
        case actionType.closeSearchForInput:
            return {
                ...state,
                expandSearchForInput: false
            };
        default:
            return {
                ...state,
                ...state
            };
    }
};

function setStaticPagesObject(data) {
    let pages = {};
    data.forEach(element => {
        pages[element.slug] = element;
    });
    return pages;
}

export default uiReducer;
