// Libreries
import {classnames, connect, React, useHistory, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {
    Box,
    GridList,
    ListItem,
    ListItemIcon,
    ListItemText,
    PropTypes,
    withWidth
} from "../../assets/fileImports/MaterialUI"
// Images
import images from "../../assets/fileImports/Images"
// font css
import "../../assets/css/font.css";
// Style
import {floatingMarkerStyle} from "./Style"
// Actions
import {openLocationDetail} from "../../action";

function FloatingMarkerList(props) {
    const {t, width} = props;
    const classes = floatingMarkerStyle();
    let history = useHistory();

    function showLocationDetailPage(locationDetail) {
        if (width === "sm" || width === "xs") {
            history.push("/locationDetail/" + locationDetail.id);
        }

        props.openLocationDetail(locationDetail, width);
    }

    return (
        <div className={classes.root}>
            <GridList
                className={classes.gridList}
            >
                {props.locationData.map((location, index) => (
                    <div
                        key={"location-list-" + index}
                        id={"location-list-" + index}
                        className={classnames({
                            [classes.locationRow]: width === "sm",
                            [classes.locationRowXS]: width === "xs" && props.locationData.length > 1,
                            [classes.singleLocationRow]: props.locationData.length === 1
                        })}
                        onClick={() => {
                            showLocationDetailPage(location)
                        }}
                    >
                        <div style={{background: "white"}}>
                            <ListItem key={"location-name-" + index} style={{position: "relative"}}>
                                <ListItemText
                                    className={classes.listModificationMultiLine}

                                    primary={location.name}
                                    secondary={location.providerName}
                                />
                                <Box className={classes.distanceDiv}>
                                    {location.distance !== "0"
                                        ? location.distance + " " + t("locationDetailsDistanceKilometers")
                                        : t("emptyDistance")
                                    }
                                </Box>
                            </ListItem>
                            <ListItem>
                                {props.filterData.offers.map(function (offer) {
                                    if (offer.id === location.offer) {
                                        return (
                                            <ListItemText
                                                className={classes.listModification}
                                                key={"location-service-provider-" + index}
                                                primary={offer.name}
                                            />
                                        );
                                    }
                                    return null;
                                })}

                                {props.filterData.genders.map(function (gender) {
                                    if (
                                        location.genders[0] !== 4 &&
                                        gender.id === location.genders[0]
                                    ) {
                                        return (
                                            <ListItemIcon
                                                key={"list-gender-icon-" + index}
                                                className={classes.locationRowImage}
                                            >
                                                <img src={images[gender.iconName]} alt=""/>
                                            </ListItemIcon>

                                        );
                                    }
                                    return null;
                                })}

                                {props.filterData.features.map(function (filterFeature) {
                                    return location.features.map(function (locationFeatureId) {
                                        if (filterFeature.id === locationFeatureId) {
                                            return (
                                                <ListItemIcon
                                                    key={"list-gender-icon-" + index}
                                                    className={classes.locationRowImage}
                                                >
                                                    <img src={images[filterFeature.iconName]} alt=""/>
                                                </ListItemIcon>
                                            );
                                        }
                                        return null;
                                    });
                                })}
                            </ListItem>
                        </div>
                    </div>
                ))}
            </GridList>
        </div>
    );
}

FloatingMarkerList.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        locationDetail: state.filter.locationDetail,
        filterData: state.filter.filterData,
        showInfoBox: state.ui.showInfoBox,
        locationData: state.filter.markerLocation,
        currentLocation: state.filter.currentLocation
    };
};

const mapDispatchToProps = dispatch => ({
    openLocationDetail: (locationDetail, width) => dispatch(openLocationDetail(locationDetail, width))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(FloatingMarkerList)));
