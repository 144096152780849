// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const infoBoxStyle = makeStyles(theme => ({
    buttonStyle: {
        marginTop: "10px",
        color: "#a31e2d",
        "&& span": {
            fontFamily: "roboto-medium"
        }
    },
    mobileButtonTextSize: {
        marginTop: "10px",
        color: "#a31e2d",
        fontFamily: "roboto-medium",
        "&& span": {
            fontFamily: "roboto-medium"
        }
    },
    fontSizeRegular: {
        fontSize: "14px",
        fontFamily: "roboto-regular",
        color: "rgba(0, 0, 0, 0.6)",
        textAlign: "left",
        "&& a": {
            color: "rgba(0, 0, 0, 0.6)",
        }
    },
    fontSizeRegularPadding: {
        fontSize: "14px",
        fontFamily: "roboto-regular",
        color: "rgba(0, 0, 0, 0.6)",
        textAlign: "left"
    },
    cardContentBG: {
        backgroundColor: "rgb(250, 250, 250)",
        paddingBottom: "16px !important",
        marginTop: "-15px"
    },
    liStyle: {
        "&& li": {
            paddingTop: "20px",
            fontFamily: "roboto-regular",
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "14px"
        }
    },
    liStyleNone: {
        "&& li": {listStyle: "none"}
    },
}));

export {infoBoxStyle}
