// Plugin
import {combineReducers} from "redux";
// Files reducer
import filterReducer from "./FilterReducer";
import uiReducer from "./Ui";

export default combineReducers({
    filter: filterReducer,
    ui: uiReducer,
});
