// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const headerStyle = makeStyles(theme => ({
    title: {
        textAlign: "left",
        fontFamily: "roboto-regular",
        fontSize: "20px",
        padding: "9px 0"
    },
    appbar: {
        backgroundColor: "#273a66",
        boxShadow: "none",
    },
    searchOpened: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        display: "inherit",
        marginTop: "5px",
        marginLeft: "0px",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(1),
            width: "100%"
        },
        "&& button": {
            padding: "10px"
        }
    },
    inputInputExpand: {
        color: "black",
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create("width"),
        width: "100%",
        fontFamily: "roboto-regular",
        [theme.breakpoints.up("sm")]: {
            width: "100%"
        },
        "&& input": {
            padding: "1px 0"
        }
    },
    redDotFilter: {
        width: "8px",
        height: "8px",
        backgroundColor: "#a31e2d",
        position: "absolute",
        right: "14px",
        top: "14px",
        borderRadius: "50%"
    },
    redDotSearch: {
        width: "8px",
        height: "8px",
        backgroundColor: "#a31e2d",
        position: "absolute",
        right: "15px",
        top: "15px",
        borderRadius: "50%"
    },
    iconSize: {
        width: "25px", height: "25px", cursor: "pointer"
    },
    mobileHeader: {
        minHeight: "48px", padding: "0 15px"
    },
    webHeader: {
        minHeight: "48px", padding: "0 32px"
    },
    cancelIcon: {
        cursor: "pointer", color: "gray"
    },
    mobileTabContaner: {
        background: "#273a66", boxShadow: "none"
    },
    iconWrapper: {
        flexGrow: "1",
        textAlign: "right"
    },
    backButton: {}
}));

export {headerStyle}
