// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const filterListViewDialogStyle = makeStyles(theme => ({
    avatar: {
        backgroundColor: "transparent",
        color: "#a31e2d",
        borderRadius: "0px"
    },
    nested: {
        paddingLeft: theme.spacing(1)
    },
    expandMore: {
        backgroundColor: "transparent"
    },
    liPaddingDecrease: {
        "&& li": {
            paddingLeft: "10px",
            paddingTop: "4px",
            paddingBottom: "4px"
        }
    },
    ulFullWidth: {
        "&& ul": {
            width: "100%"
        }
    },
    filterHeight: {
        maxHeight: "500px"
    },
    mobileFilterHightWidth: {
        height: "290px"
    },
    fontSizeAndFamily: {
        fontSize: "1.3rem",
        "&& span": {
            fontFamily: "roboto-regular"
        },
        "&& p": {
            fontFamily: "roboto-regular"
        }
    },
    selectedOptionColor: {
        margin: "0",
        "&& p": {
            color: "#a31e2d",
            fontFamily: "roboto-regular"
        }
    },
    fontSizeAndFamilyOption: {
        margin: "0",
        "&& span": {
            fontFamily: "roboto-regular"
        },
        "&& p": {
            fontFamily: "roboto-regular"
        }
    },
    buttonStyleReset: {
        color: "#a31e2d",
        // width: "100%",
        flexGrow: "1",
        "&& span": {
            fontFamily: "roboto-medium"
        }
    },
    buttonStyleOk: {
        color: "#a31e2d",
        // width: "50%",
        flexGrow: "1",
        "&& span": {
            fontFamily: "roboto-medium"
        }
    },
    dialogContentMobile: {
        width: "auto", padding: "0px", maxHeight: "400px"
    },
    dialogContentWeb: {
        padding: "0px", width: "380px", maxHeight: "500px"
    },
    filterCategory: {
        padding: "0",
    },
    filterCategoryIcons: {
        height: "36px"
    },
    filterOptionsIcon: {
        minWidth: "30px", height: "24px"
    },
    customDivider: {
        height: ".5px"
    },
    expansionPanelDetailsPadding: {
        padding: "10px 24px",
    },
    dialogueTitle: {
        "&& h2": {
            fontFamily: "roboto-medium"
        }
    }
}));

export {filterListViewDialogStyle}
