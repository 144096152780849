// Libreries
import {connect, React, useHistory, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Grid} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
// Action
import {changeMainTab} from "../../action";
// style
import {footerStyle} from "./Style"

const Footer = props => {
    const {t} = props;
    const classes = footerStyle();
    let history = useHistory();

    return (
        <Grid
            container
            className={classes.container}
            spacing={0}
            justify="center"
            alignItems="center"
        >
            {getFooter(props)}
        </Grid>
    );

    function getFooter() {
        let footerOptions = [];
        for (const slug in props.pages) {
            if (props.pages.hasOwnProperty(slug)) {
                const page = props.pages[slug];
                if (page.menuPosition === "footer") {
                    footerOptions.push(getFooterButton(slug, page.title))
                }
            }
        }
        return footerOptions
    }

    function getFooterButton(slug, footerTitle) {
        return (
            <div
                onClick={() => {
                    if (history.location.pathname.indexOf("page") !== -1) {
                        history.replace("/" + slug);
                    } else {
                        history.push("/" + slug);
                    }
                }}
                className={classes.footerLabels}
                key={"footer-" + slug}
            >
                {t(footerTitle)}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        isFilterSet: state.filter.isFilterApplied,
        pages: state.ui.pagesData
    };
};

const mapDispatchToProps = dispatch => ({
    changeMainTab: id => dispatch(changeMainTab(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(Footer));
