// Libreries
import {BrowserHistory, classnames, connect, React, useHistory} from "../../assets/fileImports/Libraries"
// css
import "../../assets/css/marker.css";
// Action
import {openLocationDetail, setMarkerLocationList} from "../../action";
// Material UI
import {PropTypes, withWidth} from "../../assets/fileImports/MaterialUI"
// Style
import {markerStyle} from "./Style"

const Marker = props => {
    const {width} = props;
    let history = useHistory();
    const classes = markerStyle();

    return (
        <div className={classnames(classes.pin, {
            [classes.clickable]: props.enableClick
        })}
             onClick={() => {
                 if (props.enableClick) {
                     let markerText = parseInt(props.shortName);
                     if (Number.isNaN(markerText)) {
                         if (width === "sm" || width === "xs") {
                             history.push("/locationDetail/" + props.locations[0].id);
                         } else {
                             BrowserHistory.push("/#/locationDetail/" + props.locations[0].id);
                         }

                         props.openLocationDetail(props.locations[0], width);
                     }
                     props.setMarkerLocationList(props.locations)
                 }
             }}
        >
            <p style={{textAlign: "center", color: "#a31e2d", fontWeight: "bold"}}>
                {props.shortName}
            </p>
        </div>
    );
};

Marker.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        locationDetail: state.filter.locationDetail,
    };
};

const mapDispatchToProps = dispatch => ({
    setMarkerLocationList: locationDetail => dispatch(setMarkerLocationList(locationDetail)),
    openLocationDetail: (locationDetail, width) => dispatch(openLocationDetail(locationDetail, width))
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(Marker));
