// Libreries
import {classnames, connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  PropTypes,
  withWidth
} from "../../assets/fileImports/MaterialUI"
//Images
import images from "../../assets/fileImports/Images"
// css
import "../../assets/css/filterListViewDialogue.css";
import "../../assets/css/font.css";

import {filterListViewDialogStyle} from "./Style"
// Action
import {
  filterLocationList,
  resetFilter,
  setExpandedIndex,
  setFilterArray,
  setSelectedFilters,
  toggleFilterDialogue
} from "../../action";

function FilterListViewDialog(props) {
    const classes = filterListViewDialogStyle();
    const {t, width} = props;
    const [scroll] = React.useState("paper");

    const selectedOption = (filterName, newFilter) => {
        let updatedFilter = Object.assign({}, props.selectedFilters);
        switch (filterName) {
            case "filterTypeOffer":
                updateFilter(updatedFilter.offers, newFilter);
                break;
            case "filterTypeFeature":
                updateFilter(updatedFilter.features, newFilter);
                break;
            case "filterTypeGender":
                updateFilter(updatedFilter.genders, newFilter);
                break;
            case "filterTypeLanguage":
                updateFilter(updatedFilter.languages, newFilter);
                break;
            default:
                break;
        }

        for (let key in updatedFilter) {
            if (
                updatedFilter.hasOwnProperty(key) &&
                updatedFilter[key].length === 0
            ) {
                updatedFilter[key] = [{name: "filterTypeAll", id: 0}];
            }
        }

        props.setSelectedFilters(updatedFilter);
    };

    function updateFilter(array, value) {
        let valueIndex = null;
        let alleIndex = null;
        array.forEach((element, index) => {
            if (element.id === value.id && element.name === value.name) {
                valueIndex = index;
            }
        });

        if (valueIndex !== null && valueIndex !== -1) {
            array.splice(valueIndex, 1);
        } else {
            array.push(value);

            array.forEach((element, index) => {
                if (element.name === "filterTypeAll") {
                    alleIndex = index;

                }
            });
            if (alleIndex !== null && alleIndex !== -1) array.splice(alleIndex, 1);
        }
    }

    return (
        <Dialog
            onClose={() => props.toggleFilterDialogue}
            aria-labelledby="simple-dialog-title"
            open={props.showFilterDialogue}
            style={width === "xs" ? {minWidth: "auto"} : {minWidth: "350px"}}
        >
            <DialogTitle id="simple-dialog-title"
                         className={classes.dialogueTitle}>{t("filterPopupTitle")}</DialogTitle>

            <DialogContent
                dividers={scroll === "paper"}
                className={classnames({
                    [classes.dialogContentMobile]: width === "xs" || width === "sm",
                    [classes.dialogContentWeb]: width !== "xs" && width !== "sm"
                })}
            >
                {props.filterArray.map((filter, index) => (
                    <ExpansionPanel
                        expanded={props.filterExpandedIndex === index}
                        onChange={() => props.setExpandedIndex(index)}
                        key={filter.filterName + "-" + index}
                        className={"filter-expansion-pannel " + classes.ulFullWidth}
                    >
                        <ExpansionPanelSummary expandIcon={<img src={images["expand_more_icon"]} alt=""/>}
                                               aria-controls="panel1bh-content" id="panel1bh-header">
                            <ListItem className={classes.filterCategory}>
                                <ListItemIcon className={classes.filterCategoryIcons}>
                                    <img src={filter.icon} alt=""/>
                                </ListItemIcon>
                                <ListItemText primary={t(filter.filterName)} secondary={t(filter.selectedFilter)}
                                              className={classes.fontSizeAndFamily}/>
                            </ListItem>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.expansionPanelDetailsPadding}>
                            <List disablePadding>
                                {filter.filterValues.map((filterData, index) => (
                                    <div
                                        key={filter.filterName + "-" + index}
                                        id={filter.filterName + "-" + index}
                                        className={
                                            (filter.filterName === "filterTypeLanguage" && "floatLeft") + " " +
                                            (filterData.isSelected ? "roundedBoxContentSelected" : "roundedBoxContent") + " " +
                                            classes.liPaddingDecrease
                                        }
                                        onClick={() =>
                                            selectedOption(filter.filterName, {
                                                name: filterData.shortName
                                                    ? filterData.shortName
                                                    : filterData.name,
                                                id: filterData.id
                                            })
                                        }
                                    >
                                        <ListItem key={filter.filterName + "-option-" + index}>
                                            {filterData.iconName !== undefined ? (
                                                <ListItemIcon className={classes.filterOptionsIcon}>
                                                    <img src={images[filterData.iconName]} alt=""/>
                                                </ListItemIcon>
                                            ) : null}
                                            <ListItemText
                                                className={
                                                    filterData.isSelected
                                                        ? classes.selectedOptionColor
                                                        : classes.fontSizeAndFamilyOption
                                                }
                                                secondary={
                                                    filterData.shortName
                                                        ? "(" + filterData.shortName + ") " + filterData.name
                                                        : filterData.name
                                                }
                                            />
                                        </ListItem>
                                    </div>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                        {props.filterArray.length - 1 !== index && <Divider/>}
                    </ExpansionPanel>
                ))}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.resetFilter()
                    }}
                    className={classes.buttonStyleReset}
                >
                    {t("filterPopupReset")}
                </Button>
                <Button
                    onClick={() => {
                        props.filterLocationList(props.selectedFilters);
                    }}
                    className={classes.buttonStyleOk}
                >
                    {t("filterPopupSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        showFilterDialogue: state.filter.showFilterDialogue,
        filterExpandedIndex: state.filter.filterExpandedIndex,
        filterArray: state.filter.filterArray,
        selectedFilters: state.filter.selectedFilters
    };
};

const mapDispatchToProps = dispatch => ({
    toggleFilterDialogue: () => dispatch(toggleFilterDialogue()),
    setExpandedIndex: index => dispatch(setExpandedIndex(index)),
    setFilterArray: filter => dispatch(setFilterArray(filter)),
    setSelectedFilters: filter => dispatch(setSelectedFilters(filter)),
    resetFilter: () => dispatch(resetFilter()),
    filterLocationList: filter => dispatch(filterLocationList(filter))
});

FilterListViewDialog.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(FilterListViewDialog)));
