// Libreries
import {classnames, connect, React, ReactHtmlParser, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {CircularProgress, Container, PropTypes, withWidth} from "../../assets/fileImports/MaterialUI"
// Components
import {Footer, SimpleHeader} from "../../assets/fileImports/Components"
//Style
import {staticPageStyle} from "./Style"
// CSS
import "../../assets/css/staticPage.css"
// Action
import {changeMainTab} from "../../action";

function StaticPage(props) {
    const classes = staticPageStyle();
    const {width} = props;

    if (!props.pages || JSON.stringify(props.pages) === '{}') {
        return <div className={classes.loaderClass}>
            <CircularProgress/>
        </div>;
    }

    let currentPage;
    if (props.match && props.match.params.slug)
        currentPage = props.pages[props.match.params.slug];
    else
        currentPage = props.pages[props.pageIndex];

    if (width !== "sm" && width !== "xs") {
        return <div>
            {currentPage.menuPosition === "header"
                ? null
                : <SimpleHeader title={currentPage.title} enableBackBTN={true}/>}

            <Container
                className={classnames({
                    [classes.fullPageView]: currentPage.menuPosition === "footer",
                    [classes.tabView]: currentPage.menuPosition === "header",
                })}>
                <Container className={classnames({
                    [classes.bodyContainer]: true,
                    [classes.fullPageHtmlContainer]: currentPage.menuPosition === "footer",
                    [classes.tabHtmlContainer]: currentPage.menuPosition === "header",
                })}>
                    <div> {ReactHtmlParser(currentPage.html)} </div>
                </Container>
            </Container>

            {currentPage.menuPosition === "header"
                ? null
                : <Footer/>}
        </div>
    } else {
        return (
            <div className={classes.root}>
                <SimpleHeader title={currentPage.title} enableBackBTN={true}/>
                <div className={classes.mobileView}>
                    {ReactHtmlParser(currentPage.html)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        pages: state.ui.pagesData,
        pageIndex: state.ui.tabIndex
    };
};

StaticPage.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapDispatchToProps = dispatch => ({
    changeMainTab: id => dispatch(changeMainTab(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(StaticPage)));
