// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const staticPageStyle = makeStyles(theme => ({
    tabView: {
        maxWidth: "100% !important",
        padding: "0 !important",
    },
    tabHtmlContainer: {
        minHeight: "calc(100vh - 140px)",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        maxWidth: "100%",
        backgroundColor: "#ffffff",
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    fullPageView: {
        maxWidth: "100% !important",
        paddingTop: "48px"
    },
    fullPageHtmlContainer: {
        minHeight: "calc(100vh - 103px)",
        marginTop: "20px",
        borderRadius: "10px",
        overflow: "auto",
        maxWidth: "100%",
        backgroundColor: "#fff"
    },
    mobileView: {
        padding: " 50px 10px",
        overflow: "auto",
    },
    appbar: {
        backgroundColor: "#273a66",
        boxShadow: "none"
    },
    bodyContainer: {
        backgroundColor: "#fff",
        display: "flow-root",
        overflow: "auto",
        maxWidth: "100%",
    },
    loaderClass: {
        position: "absolute",
        top: "0",
        bottom: "0%",
        left: "0",
        right: "0%",
        backgroundColor: "white",
        zIndex: "99",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export {staticPageStyle}
