// UI reducer actions
export const changeMainTab = index => ({type: actionType.changeMainTab, index});
export const closeLocationDetail = () => ({type: actionType.closeLocationDetail});
export const openLocationDetail = (locationDetail, width) => ({
    type: actionType.openLocationDetail,
    locationDetail: locationDetail,
    deviceWidth: width
});
export const setLocationDetailFromUrl = (id) => ({type: actionType.setLocationDetailFromUrl, id: id});
export const toggleInfoBox = () => ({type: actionType.toggleInfoBox});

// Mobile Ui
export const toggleDrawer = () => ({type: actionType.toggleDrawer});
export const expandSearchForInput = () => ({type: actionType.expandSearchForInput});
export const closeSearchForInput = () => ({type: actionType.closeSearchForInput});

// filter reducer action
export const toggleFilterDialogue = () => ({type: actionType.toggleFilterDialogue});
export const setExpandedIndex = index => ({type: actionType.setExpandedIndex, index: index});
export const setFilterData = data => ({type: actionType.setFilterData, data: data});
export const setFilterArray = data => ({type: actionType.setFilterArray, data: data});
export const setSelectedFilters = filters => ({type: actionType.setSelectedFilters, filters: filters});
export const resetFilter = () => ({type: actionType.resetFilter});

// Location Reducer action
export const searchInLocationList = searchValue => ({type: actionType.searchInLocationList, searchValue: searchValue});
export const filterLocationList = (filters, presetFilterName) => ({
    type: actionType.filterLocationList,
    filters: filters,
    presetFilterName: presetFilterName
});
export const setLocationFilterData = (locations, filter) => ({
    type: actionType.setLocationFilterData,
    locations: locations,
    filter: filter
});
export const setMarkerLocationList = locations => ({type: actionType.setMarkerLocationList, locations: locations});
export const resetMarkerLocationList = () => ({type: actionType.resetMarkerLocationList});
export const resetMobileMarkerLocationList = () => ({type: actionType.resetMobileMarkerLocationList});
export const setCurrentLocation = (currentLocation) => ({
    type: actionType.setCurrentLocation,
    currentLocation: currentLocation
});

// Pages Reducer Action
export const setPagesData = data => ({type: actionType.setPagesData, data: data});

// FirstTime Website Opened
export const closeTheWelcomeFilter = () => ({type: actionType.closeTheWelcomeFilter});

// Action type
export const actionType = {
    setLocationFilterData: "set-location-filter-data",
    toggleDrawer: "toggle-drawer",
    closeTheWelcomeFilter: "close-welcome-filter",
    toggleInfoBox: "toggle-info-box",
    changeMainTab: "change-main-tab",
    toggleFilterDialogue: "toggle-filter-dialogue",
    searchInLocationList: "search-in-location-list",
    setExpandedIndex: "set-expanded-index",
    setFilterArray: "set-filter-array",
    setSelectedFilters: "set-selected-filters",
    resetFilter: "reset-filter",
    openLocationDetail: "open-location-detail",
    closeLocationDetail: "close-location-detail",
    setPagesData: "set-pages-data",
    filterLocationList: "filter-location-list",
    setMarkerLocationList: "set-marker-location-list",
    resetMarkerLocationList: "reset-marker-location-list",
    resetMobileMarkerLocationList: "reset-mobile-marker-location-list",
    expandSearchForInput: "expand-search-for-input",
    closeSearchForInput: "close-search-for-input",
    setLocationDetailFromUrl: "set-location-detail-from-url",
    setCurrentLocation: "set-current-location"
};
