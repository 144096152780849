// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const appStyle = makeStyles(theme => ({
    loaderClass: {
        position: "absolute",
        top: "0",
        bottom: "0%",
        left: "0",
        right: "0%",
        backgroundColor: "white",
        zIndex: "99",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export {appStyle}
