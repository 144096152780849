// Libreries
import {connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {CircularProgress, PropTypes, withWidth} from "../../assets/fileImports/MaterialUI"
// Components
import {
    Facilities,
    FilterListViewDialog,
    Footer,
    LeftDrawer,
    MobileHeader,
    SimpleHeader,
    TabUI,
    WelcomeContent
} from "../../assets/fileImports/Components"
// Style
import {appStyle} from "./Style"
// Actions
import {setLocationDetailFromUrl} from "../../action";

function App(props) {
    const classes = appStyle();
    const {width} = props;

    // check for location detail ID in url for showing detail dialogue in desktop
    if (props.match && props.match.params.id) {
        props.setLocationDetailFromUrl(props.match.params.id)
    }

    function loadPages() {
        if (props.locations.length === 0 && JSON.stringify(props.pages) === '{}') {
            return <div className={classes.loaderClass}>
                <CircularProgress/>
            </div>;
        } else {
            return <div>
                <WelcomeContent/>
                {width === "sm" || width === "xs"
                    ? <div>
                        <MobileHeader/>
                        <Facilities/>
                    </div>
                    : <div>
                        <SimpleHeader title="locationTitle"/>
                        <TabUI/>
                        <Footer/>
                    </div>
                }
            </div>
        }
    }

    return (
        <div>
            <LeftDrawer/>
            <FilterListViewDialog/>
            {loadPages()}
        </div>
    );
}

App.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        pages: state.ui.pagesData,
        locations: state.filter.locationData,
    };
};

const mapDispatchToProps = dispatch => ({
    setLocationDetailFromUrl: (id) => dispatch(setLocationDetailFromUrl(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withWidth()(withTranslation("common")(App))
);
