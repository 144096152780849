// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const leftDrawerStyle = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    },
    typoCapitalize: {
        "&& span": {
            fontFamily: "roboto-regular"
        }
    },
    logoSpace: {
        width: "100%",
        height: "auto",
        backgroundColor: "#fff",
        "&& img": {
            width: "100%",
            height: "auto",
            backgroundColor: "#000000",
        }
    }
});

export {leftDrawerStyle}
