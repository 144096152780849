// Libreries
import {classnames, connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {
    Avatar,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    PropTypes,
    withWidth
} from "../../assets/fileImports/MaterialUI"
// Components
import {SearchTab} from "../../assets/fileImports/Components"
// css
import "../../assets/css/facilities.css";
import "../../assets/css/font.css";
//Images
import images from "../../assets/fileImports/Images"
// Action
import {toggleFilterDialogue, toggleInfoBox} from "../../action";
// Style
import {facilitiesHeaderStyle} from "./Style"

function FacilitiesHeader(props) {
    const classes = facilitiesHeaderStyle();
    const {width} = props;

    return (
        <Grid
            container
            spacing={0}
            className={classnames({
                [classes.header]: width !== "xs" && width !== "sm"
            })}
        >
            {getHeaderGrid({
                props: props,
                icon: "info_icon",
                showRedDot: false,
                primaryText: props.presetFilterName === "" ? "globalFilterNone" : props.presetFilterName,
                secondaryText: "",
                isExpanded: props.showInfoBox,
                onExpand: () => props.toggleInfoBox(),
            })}

            {width !== "xs" && width !== "sm" && (
                getHeaderGrid({
                    props: props,
                    icon: "filter_icon",
                    iconClick: () => props.toggleFilterDialogue(),
                    showRedDot: props.isFilterApplied,
                    primaryText: "filterPopupTitle",
                    secondaryText: props.allSelectedFilterString === "" ? "filterTypeAll" : props.allSelectedFilterString,
                    isExpanded: props.showFilterDialogue,
                    onExpand: () => props.toggleFilterDialogue(),
                })
            )}

            <SearchTab/>
        </Grid>
    );
}

function getHeaderGrid({props, icon, iconClick, showRedDot, primaryText, secondaryText, isExpanded, onExpand}) {
    const classes = facilitiesHeaderStyle();
    const {t, width} = props;

    return <Grid item xs={12} sm={12} md={4} lg={4}>
        <Paper
            className={classnames({
                [classes.facilitiesHeaderMobileView]: width === "xs" || width === "sm",
                [classes.facilitiesHeaderDesktopView]: width !== "xs" && width !== "sm"
            })}
        >
            <ListItem>
                <ListItemIcon>
                    <Avatar className={classes.avatar} onClick={iconClick && iconClick}>
                        <img src={images[icon]} alt="" className={classes.imageSize}
                             style={iconClick ? {cursor: "pointer"} : {cursor: "default"}}/>
                        {showRedDot === true && <div className={classes.redDot}></div>}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    className={classnames({
                        [classes.mobileListItemFontSize]: width === "xs" || width === "sm",
                        [classes.listItemFontSize]: width !== "xs" && width !== "sm"
                    })}
                    primary={t(primaryText)}
                    secondary={t(secondaryText)}
                />
                <IconButton
                    className={classnames(classes.expand, {[classes.expandOpen]: isExpanded})}
                    onClick={onExpand}
                    aria-expanded={isExpanded}
                    aria-label="show more"
                >
                    <img src={images["expand_more_icon"]} alt="" className={classes.imageSize}/>
                </IconButton>
            </ListItem>
        </Paper>
    </Grid>
}

FacilitiesHeader.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        isFilterApplied: state.filter.isFilterApplied,
        presetFilterName: state.filter.presetFilterName,
        showInfoBox: state.ui.showInfoBox,
        showFilterDialogue: state.filter.showFilterDialogue,
        allSelectedFilterString: state.filter.allSelectedFilterString
    };
};

const mapDispatchToProps = dispatch => ({
    toggleFilterDialogue: () => dispatch(toggleFilterDialogue()),
    toggleInfoBox: () => dispatch(toggleInfoBox())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(FacilitiesHeader)));
