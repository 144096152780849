// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const welcomeContentStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        boxShadow: "none",
        padding: "20px",
        backgroundColor: "rgb(250, 250, 250)",
        border: "1px solid rgba(0, 0, 0, 0.12);",
        borderRadius: "6px",
        cursor: "pointer",
    },
    welcomeButtonsTitle: {
        textAlign: "left",
        fontFamily: "roboto-regular",
        fontSize: "16px",
        width: "100%"
    },
    welcomeButtonsDesc: {
        fontSize: "14px",
        textAlign: "left",
        marginTop: "0px",
        color: "rgba(0,0,0,0.6)",
        fontFamily: "roboto-regular"
    },
    fontSizeForHeader: {
        fontFamily: "roboto-medium",
        textAlign: "left",
        marginTop: "10px",
        fontSize: "20px"
    },
    skipButton: {
        color: "#a31e2d",
        fontFamily: "roboto-medium",
        fontSize: "16px",
        padding: "0",
        width: "100%",
        textAlign: "center"
    },
    paragraphUrl: {
        color: "#273a66",
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "roboto-regular",
        "&& a": {
            color: "#273a66",
        }
    },
    welcomeSelectFilterSubTitle: {
        textAlign: "left",
        paddingTop: "10px",
        paddingBottom: "20px",
        fontFamily: "roboto-regular",
        color: "rgba(0,0,0,0.6)",
        fontSize: "14px"
    },
}));

export {welcomeContentStyle}
