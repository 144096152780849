// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"
// Images
import images from "../../assets/fileImports/Images"

const floatingMarkerStyle = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        position: "fixed",
        width: "100%",
        height: "auto",
        zIndex: "1",
        bottom: "40px",
        backgroundColor: "transparent",
        "&& ul": {
            backgroundColor: "transparent",
            width: "100%",
            "&:last-child": {
                margin: "100px"
            },
            "&& li": {
                height: "100px"
            }
        }
    },
    gridList: {
        flexWrap: "nowrap",
        backgroundColor: "transparent",
        height: "auto !important"
    },
    listModification: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "0px",
        paddingRight: "10px"
    },
    listModificationMultiLine: {
        "&& span": {
            margin: "0px",
            width: "50%",
        },
        "&& p": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: "0px"
        },
        margin: "0px"
    },
    locationRow: {
        margin: "0",
        padding: "0 5px !important",
        height: "max-content !important",
        maxWidth: "320px",
        minWidth: "320px",
        "&& li": {
            maxWidth: "320px"
        }
    },
    locationRowXS: {
        margin: "0",
        padding: "0 5px !important",
        height: "max-content !important",
        maxWidth: "300px",
        minWidth: "300px",
        "&& li": {
            maxWidth: "300px"
        }
    },
    locationRowImage: {
        padding: "0 5px",
        minWidth: "18px",
        "&& img": {
            width: "18px"
        }
    },
    singleLocationRow: {
        height: "max-content !important",
        margin: "auto",
        maxWidth: "max-content",
        minWidth: "350px",
        "&& li": {
            maxWidth: "auto"
        }
    },
    distanceDiv: {
        position: "relative",
        right: "0px",
        top: "0px",
        display: "block",
        height: "40px",
        color: "#a31e2d",
        fontFamily: "roboto-regular",
        fontSize: "12px",
        textAlign: "right"
    },
}));

const mapStyle = makeStyles(theme => ({
    shortHeightMap: {
        height: "270px !important"
    },
    childGrid: {
        flex: "1 1 auto",
        width: "calc(100%)",
        overflow: "hidden",
        background: "#fff",
        borderBottomRightRadius: "10px",
    },
    mapsDesktopView: {
        height: "calc(100vh - 212px)",
        position: "relative"
    },
    mapMobileView: {
        height: "calc(100vh - 97px)",
        position: "absolute"
    },
    container: {
        backgroundColor: "#f1f1f1"
    },
}));

const markerStyle = makeStyles(theme => ({
    pin: {
        width: "45px",
        height: "45px",
        borderRadius: "50% 50% 50% 0",
        backgroundImage: `url(${images["marker-pin"]})`,
        position: "absolute",
        left: "50%",
        top: "50%",
        margin: "-20px 0 0 -20px",
        animationName: "bounce",
        animationFillMode: "both",
        animationDuration: "1s",
    },
    clickable: {
        cursor: "pointer"
    }
}));

export {floatingMarkerStyle, mapStyle, markerStyle}
