// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const detailPageStyles = makeStyles(theme => ({
    mainGrid: {
        textAlign: "center", padding: "4px 8px"
    },
    paper: {
        borderRadius: "0px",
        boxShadow: "none",
        backgroundColor: "rgb(250, 250, 250)"
    },
    listItem: {
        padding: "4px 16px"
    },
    divider: {
        margin: "4px 0"
    },
    detailText: {
        padding: "0 0 0 16px",
        fontSize: "16px",
        margin: "0",
        fontFamily: "roboto-regular"
    },
    detailTextImage: {
        minWidth: "24px",
        alignSelf: "flex-start",
        "&& img": {
            width: "24px"
        }
    },
    subHeading: {
        float: "left",
        color: "#273a66",
        fontSize: "12px",
        padding: "4px 16px 8px 16px",
        fontFamily: "roboto-regular"
    },
    heading: {
        textAlign: "left",
        color: "rgb(39, 58, 102)",
        padding: "12px 16px 4px 16px",
        fontSize: "20px",
        fontFamily: "roboto-medium"
    },
    appbar: {
        backgroundColor: "#273a66",
        boxShadow: "none"
    },
    pageTitle: {
        fontSize: "20px",
        fontFamily: "roboto-regular"
    },
    telephoneBlock: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.6)",
        fontSize: "14px",
        overflowWrap: "break-word",
        fontFamily: "roboto-regular"
    },
    contactDetail: {
        color: "rgba(0,0,0,0.6)",
        fontSize: "14px",
        overflowWrap: "break-word",
        fontFamily: "roboto-regular"
    },
    contactHeading: {
        color: "#a31e2d",
        fontSize: "14px",
        fontWeight: "700",
        paddingBottom: "5px"
    },
    noLocationDetail: {
        padding: "16px 16px 4px 16px",
        fontSize: "16px",
        margin: "0",
        fontFamily: "roboto-regular"
    },
    map: {
        flex: "1 1 auto",
        height: "calc(100%)",
        width: "calc(100%)",
        overflow: "auto",
        marginTop: "12px"
    },
    mapGrid: {
        height: "270px !important",
        flex: "1 1 auto",
        width: "calc(100%)",
        overflow: "hidden",
        background: "#fff",
        borderBottomRightRadius: "10px",
        position: "absolute"
    },
    noInfoText: {
        float: "left",
        paddingLeft: "20px",
        paddingBottom: "10px",
        fontFamily: "roboto-regular",
        color: "rgba(0, 0, 0,0.87)",
        paddingTop: "63px"
    },
    additionalInfo: {
        paddingBottom: "18px"
    },
    contactsGrid: {
        padding: "10px 0"
    },
    addressGrid: {
        padding: "12px 16px 4px 16px"
    },
    addressGridOne: {
        padding: "0 8px 0 0"
    },
    addressGridThree: {
        textAlign: "right", padding: "0px"
    },
    distanceText: {
        padding: "8px 0 0 0",
        fontSize: "12px",
        color: "#a31e2d",
        fontFamily: "roboto-regular"
    },
    loaderClass: {
        position: "absolute",
        top: "0",
        bottom: "0%",
        left: "0",
        right: "0%",
        backgroundColor: "white",
        zIndex: "99",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    detailPageWrapper: {
        height: "calc(100vh)",
        backgroundColor: "rgb(250, 250, 250)"
    }
}));

const detailDialogueStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        height: "100%"
    },
    parentDivContainer: {
        position: "absolute", width: "100%", bottom: "0px"
    },
    childDivInfo: {
        position: "absolute",
        bottom: "0px",
        zIndex: "1",
    },
    gridWidth: {
        width: "calc(600px)"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        minHeight: "350px",
        borderRadius: "0px"
    },
    contactBoxShadow: {
        "&& h6": {
            fontFamily: "roboto-medium",
            fontSize: "14px",
            letterSpacing: "2px",
            overflow: "hidden",
            color: "#a31e2d"
        },
        "&& p": {
            fontFamily: "roboto-regular",
            fontSize: "12px",
            wordWrap: "break-word"
        },
        marginTop: "0px",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0,0.12)",
        marginBottom: "12px",
    },
    wrapText: {
        flex: "1 1 0px",
        "&& p": {
            fontFamily: "roboto-regular",
            fontSize: "12px",
            wordWrap: "break-word"
        }
    },
    liPadding: {
        padding: "0px",
        "&& div": {
            minWidth: "40px"
        }
    },
    liMargin: {
        margin: "0px"
    },
    fontFamilyForSpan: {
        maxWidth: "100%",
        "&& span": {
            fontFamily: "roboto-regular",
            color: "rgba(0, 0, 0,0.87)"
        }
    },
    contactParentDivePaper: {
        borderRadius: "0px",
        boxShadow: "none",
        padding: "0px",
        "&& ul": {
            paddingTop: "0px",
            paddingBottom: "0"
        }
    },
    paperContainerMain: {
        borderRadius: "0px",
        boxShadow: "none",
        minHeight: "300px",
        overflow: "auto",
        padding: "20px"
    },
    paperContainerChild: {
        flex: "1 1 0px",
        borderRadius: "0px",
        boxShadow: "none",
        paddingRight: "20px",
        minHeight: "300px"
    },
    paperHeadingContainer: {
        borderRadius: "0px", boxShadow: "none",
    },
    typoH5: {
        textAlign: "left",
        paddingBottom: "5px",
        fontSize: "18px",
        fontFamily: "roboto-medium",
        color: "#273a66"
    },
    typoH6: {
        float: "left",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontFamily: "roboto-regular",
        color: "#273a66"
    },
    locInfoTitleTwo: {
        float: "left",
        paddingTop: "15px",
        paddingBottom: "10px",
        color: "#273a66",
        fontFamily: "roboto-regular"
    },
    listIcons: {
        width: "40px",
        alignSelf: "flex-start",
        padding: "4px 0",
        "&& img": {
            width: "25px"
        }
    },
    calcelCustomImage: {
        float: "right",
        paddingBottom: "10px",
        cursor: "pointer"
    },
    contactBlockIcons: {
        minWidth: "60px",
        "&& img": {
            width: "40px"
        }
    },
}));

const noLocationInfoStyle = makeStyles(theme => ({
    root: {
        position: "relative",
        height: "100%"
    },
    childDivInfo: {
        position: "absolute",
        bottom: "0px",
        zIndex: "1"
    },
    gridWidth: {
        width: "calc(600px)"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        minHeight: "350px"
    },
    centerNotInfoParagraph: {
        position: "relative",
        height: "100%",
        "&& img": {
            float: "right",
            padding: "20px",
            cursor: "pointer"
        },
        "&& p": {
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            color: "rgba(0, 0, 0,0.87)",
            fontFamily: "roboto-regular"
        }
    },
    paperContainer: {
        borderRadius: "0px",
        boxShadow: "none",
        height: "350px"
    },
    parentDivContainer: {
        position: "absolute", width: "100%", bottom: "0px"
    }
}));

export {detailPageStyles, detailDialogueStyles, noLocationInfoStyle};
