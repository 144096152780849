// Libraries
import {connect, GoogleMapReact, React, withTranslation} from "../../assets/fileImports/Libraries";
// Created components
import {Marker, SimpleHeader} from "../../assets/fileImports/Components";
// Material UI
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography
} from "../../assets/fileImports/MaterialUI";
// Images
import images from "../../assets/fileImports/Images";
// css
import "../../assets/css/font.css";
// Actions
import {changeMainTab, closeLocationDetail} from "../../action";
// conig file
import config from "../../config.json"
// Styles
import {detailPageStyles} from "./Style";

function LocationDetailPage(props) {
    topFunction();

    const {t} = props;
    const classes = detailPageStyles();
    let locationDetail = {};

    if (props.locations.length === 0) {
        return <div className={classes.loaderClass}>
            <CircularProgress/>
        </div>;
    } else {

        if (props.match && props.match.params.id) {
            props.locations.forEach(location => {
                if (location.id.toString() === props.match.params.id) {
                    locationDetail = location
                }
            });
        }

        if (locationDetail.constructor === Object && Object.entries(locationDetail).length === 0) {
            return null
        }

        if (shouldShowNoDataMSG(locationDetail)) {
            return <div className={classes.detailPageWrapper}>
                <SimpleHeader title="location_detail_appbar_heading" enableBackBTN={true}/>

                <Box className={classes.noInfoText}>
                    {t("no_location_info")}
                </Box>
            </div>
        } else {
            return (
                <div className={classes.detailPageWrapper}>
                    <SimpleHeader title="location_detail_appbar_heading" enableBackBTN={true}/>
                    <Grid container spacing={0} style={{paddingTop: "48px"}}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.paper}>
                                <Box className={classes.heading}>
                                    {locationDetail.name}
                                </Box>
                                <Box className={classes.subHeading}>
                                    {locationDetail.providerName}
                                </Box>
                                {locationDetail.offer
                                    ? <ListItem className={classes.listItem}>
                                        <ListItemIcon className={classes.detailTextImage}>
                                            <img src={images["home_icon"]} alt=""/>
                                        </ListItemIcon>
                                        <Box
                                            key={"details-offer-name-" + locationDetail.id}
                                            className={classes.detailText}
                                        >
                                            {locationDetail.offerObject.name + " (" + locationDetail.offerObject.shortName + ")"}
                                        </Box>
                                    </ListItem>
                                    : null}
                                {
                                    locationDetail.genders.length || locationDetail.features.length || locationDetail.languages.length || !isEmpty(locationDetail.additionalInformation)
                                        ? <div>
                                            <Divider className={classes.divider}/>
                                            <Box
                                                className={classes.subHeading}> {t("locationDetailsSectionFeaturesTitle")}   </Box>
                                            <List component="nav" aria-label="secondary mailbox folders"
                                                  key="general-detail">
                                                {/* Gender */}
                                                {
                                                    locationDetail.genderObjects.map(function (gender, index) {
                                                        return getDetailOfListArray(classes, "detail-gender-" + index, gender)
                                                    })
                                                }
                                                {/* Features */}
                                                {locationDetail.featureObjects.map(function (
                                                    feature, index
                                                ) {
                                                    return getDetailOfListArray(classes, "detail-feature-" + index, feature)
                                                })}

                                                {/* Language */}
                                                {locationDetail.languageObject.map(function (
                                                    language, index
                                                ) {
                                                    return (getDetailOfListArray(classes, "detail-language-" + index, language, index));
                                                })}

                                                {/* Addtional info */}
                                                {!isEmpty(locationDetail.additionalInformation)
                                                    ? (getDetailOfListArray(classes, "detail-info-" + locationDetail.id, {
                                                        name: locationDetail.additionalInformation,
                                                        iconName: "star_icon"
                                                    }))
                                                    : null}
                                            </List>
                                        </div>
                                        : null
                                }

                                {/* Contacts */}
                                {
                                    isEmpty(locationDetail.contactTelephone) && isEmpty(locationDetail.contactEmail) && isEmpty(locationDetail.contactWebsite)
                                        ? null
                                        : <div>
                                            <Divider className={classes.divider}/>

                                            <Box
                                                className={classes.subHeading}> {t("locationDetailsSectionContactTitle")} </Box>
                                            <Grid container spacing={0}>
                                                <Grid container item xs={12} spacing={0} className={classes.contactsGrid}>
                                                    <React.Fragment>
                                                        {getContactBlocks(
                                                            classes,
                                                            images['call_icon'],
                                                            t("locationDetailsSectionContactPhone"),
                                                            locationDetail.contactTelephone,
                                                            openTelephone
                                                        )}
                                                        {getContactBlocks(
                                                            classes,
                                                            images["email_icon"],
                                                            t("locationDetailsSectionContactEmail"),
                                                            locationDetail.contactEmail,
                                                            value => {
                                                                window.location.href = `mailto:${value}?subject=${t("locationDetailsContactEmailDefaultSubject")}&body=${t("locationDetailsContactEmailDefaultBody")}`;
                                                            }
                                                        )}

                                                        {getContactBlocks(
                                                            classes,
                                                            images["earth_icon"],
                                                            t("locationDetailsSectionContactWebsite"),
                                                            locationDetail.contactWebsite,
                                                            openWebSite
                                                        )}
                                                    </React.Fragment>
                                                </Grid>
                                            </Grid>
                                        </div>
                                }
                                {/* Address */}
                                {
                                    isEmpty(locationDetail.addressStreet) && isEmpty(locationDetail.addressPostalCode) && isEmpty(locationDetail.addressCity)
                                        ? null
                                        : <div>
                                            <Divider className={classes.divider}/>
                                            <Box className={classes.subHeading}>
                                                {t("locationDetailsSectionMapTitle")}
                                            </Box>
                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.addressGrid}
                                            >
                                                <Grid container item xs={12} spacing={0}>
                                                    <React.Fragment>
                                                        <Grid item xs={9} className={classes.addressGridOne}>
                                                            {locationDetail.addressStreet && <Grid item xs={12}>
                                                                <Box
                                                                    className={classes.detailText}
                                                                    style={{padding: "0 0 10px 0"}}
                                                                >
                                                                    {locationDetail.addressStreet}
                                                                </Box>
                                                            </Grid>}
                                                            {(locationDetail.addressPostalCode || locationDetail.addressCity) && (
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        className={classes.detailText}
                                                                        style={{padding: "0 "}}
                                                                    >
                                                                        {locationDetail.addressPostalCode + " " + locationDetail.addressCity}
                                                                    </Box>
                                                                </Grid>)
                                                            }
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            className={classes.addressGridThree}
                                                        >
                                                            <Grid item xs={12}>
                                                                <IconButton className={classes.detailTextImage}
                                                                            style={{padding: "0px"}}
                                                                            onClick={() => openGoogleMap(locationDetail.latLng)}>
                                                                    <img src={images["location_icon"]} alt=""/>
                                                                </IconButton>
                                                                <Typography className={classes.distanceText}>
                                                                    {
                                                                        locationDetail.distance !== "0"
                                                                            ? locationDetail.distance + " " + t("locationDetailsDistanceKilometers")
                                                                            : t("emptyDistance")
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                </Grid>
                                            </Grid>
                                        </div>
                                }
                                {/* Map */}
                                {locationDetail.latLng.lat && locationDetail.latLng.lat &&
                                <Grid container className={classes.map} spacing={0}>
                                    <Grid
                                        item
                                        sm={12}
                                        xs={12}
                                        className={classes.mapGrid}
                                    >
                                        <GoogleMapReact
                                            bootstrapURLKeys={{key: config.mapApiKey}}
                                            style={{position: "static"}}
                                            defaultZoom={11}
                                            options={{gestureHandling: "greedy", zoomControl: false}}
                                            center={{
                                                lat: locationDetail.latLng.lat,
                                                lng: locationDetail.latLng.lng
                                            }}
                                        >
                                            <Marker
                                                lat={locationDetail.latLng.lat}
                                                lng={locationDetail.latLng.lng}
                                                key={"coordinate-detail"}
                                                shortName={locationDetail.offerObject.shortName}
                                                enableClick={false}
                                            />
                                        </GoogleMapReact>
                                    </Grid>
                                </Grid>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    function getDetailOfListArray(classes, listItmeKey, data, index) {
        return <ListItem className={classes.listItem} key={listItmeKey}>
            <ListItemIcon className={classes.detailTextImage}>
                {index === 0
                    ? < img src={index === 0 ? images["comments_icon"] : ""} alt=""/>   // for language
                    : <img src={images[data.iconName]} alt=""/>                         // for others
                }
            </ListItemIcon>
            <Box className={classes.detailText}>
                {data.name}
            </Box>
        </ListItem>
    }

    function isEmpty(str) {
        return (!str || 0 === str.trim().length);
    }

    function shouldShowNoDataMSG(location) {
        return (
            !location.genders.length &&
            !location.features.length &&
            !location.languages.length &&
            isEmpty(location.additionalInformation) &&
            isEmpty(location.contactTelephone) &&
            isEmpty(location.contactEmail) &&
            isEmpty(location.contactWebsite) &&
            isEmpty(location.addressStreet) &&
            isEmpty(location.addressPostalCode) &&
            isEmpty(location.addressCity)
        );
    }

    function openGoogleMap(location) {
        let coordinates = location.lat + "," + location.lng;
        if (
            /* if we're on iOS, open in Apple Maps */
            navigator.platform.indexOf("iPhone") !== -1 ||
            navigator.platform.indexOf("iPad") !== -1 ||
            navigator.platform.indexOf("iPod") !== -1
        )
            window.open("maps://maps.google.com/maps?daddr=" + coordinates);
        else
            window.open(
                "https://www.google.com/maps/search/?api=1&query=" + coordinates
            );
    }

    function openWebSite(value) {
        window.open(value, "_blank");
    }

    function openTelephone(value) {
        window.open("tel:" + value, "_self");
    }

    function getContactBlocks(classes, icon, title, value, callback) {
        if (!value || value === "") {
            return null;
        }

        return (
            <Grid item xs={4} className={classes.mainGrid}
                  onClick={() => {
                      if (callback !== undefined) callback(value);
                  }}
            >
                <Grid item xs={12} className={classes.detailTextImage}>
                    <img src={icon} alt=""/>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.contactHeading}>
                        {title}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.contactDetail}>
                        {value}
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        locations: state.filter.locationData,
    };
};

const mapDispatchToProps = dispatch => ({
    closeLocationDetail: () => dispatch(closeLocationDetail()),
    changeMainTab: id => dispatch(changeMainTab(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(LocationDetailPage));
