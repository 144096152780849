// Libreries
import {classnames, connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Grid, PropTypes, withWidth} from "../../assets/fileImports/MaterialUI"
// Map plugin
import GoogleMapReact from "google-map-react";
// Component
import {FloatingMarkerList, LocationDetailDialogue, Marker} from "../../assets/fileImports/Components"
// Style
import {mapStyle} from "./Style"
// conig file
import config from "../../config.json"
// Actions
import {resetMarkerLocationList, resetMobileMarkerLocationList} from "../../action";

function Map(props) {
    const classes = mapStyle();
    const {width} = props;

    const getAllMarkers = () => {
        let markers = [];
        for (let index = 0; index < props.groupedLocations.length; index++) {
            markers.push(
                <Marker
                    lat={props.groupedLocations[index].locations[0].latLng.lat}
                    lng={props.groupedLocations[index].locations[0].latLng.lng}
                    key={"coordinate-" + index}
                    shortName={props.groupedLocations[index].markerName}
                    locations={props.groupedLocations[index].locations}
                    enableClick={true}
                />
            );
        }
        return markers;
    };

    function getCenter() {
        if (props.locationData.length) {
            if (props.showLocationDetails &&
                props.locationDetail.latLng !== undefined &&
                (props.locationDetail.latLng.lat !== null ||
                    props.locationDetail.latLng.lng !== null)) {
                return {
                    lat: props.locationDetail.latLng.lat,
                    lng: props.locationDetail.latLng.lng
                };
            } else {
                props.locationData.forEach(location => {
                    if (location.latLng.lat && location.latLng.lng) {
                        return {
                            lat: location.latLng.lat,
                            lng: location.latLng.lng
                        };
                    }
                });
            }
        }

        return {
            lat: 52.5200,
            lng: 13.4050
        };
    }

    return (
        <Grid
            item
            sm={12}
            xs={12}
            md={8}
            lg={8}
            className={classnames({
                [classes.childGrid]: true,
                [classes.mapsDesktopView]: width !== "xs" && width !== "sm",
                [classes.mapMobileView]: width === "xs" || width === "sm"
            })}
            style={
                width === "xs" || width === "sm"
                    ? {position: "absolute"}
                    : {position: "relative"}
            }
        >
            {props.showLocationDetails && width !== "xs" && width !== "sm" ? (
                <LocationDetailDialogue/>
            ) : null}

            {props.isMarkerClicked &&
            (width === "xs" || width === "sm") ? (
                <FloatingMarkerList/>
            ) : null}

            {props.locationData ? (
                <GoogleMapReact
                    bootstrapURLKeys={{key: config.mapApiKey}}
                    style={{position: "static"}}
                    defaultZoom={11}
                    options={{gestureHandling: "greedy", zoomControl: false}}
                    center={getCenter()}
                    onClick={() => {
                        if (width === "xs" || width === "sm") {
                            props.resetMobileMarkerLocationList();
                        } else {
                            props.resetMarkerLocationList();
                        }
                    }}
                >
                    {props.showLocationDetails && width !== "xs" && width !== "sm" ? (
                            <Marker
                                lat={props.locationDetail.latLng.lat}
                                lng={props.locationDetail.latLng.lng}
                                key={"coordinate-detail"}
                                shortName={props.locationDetail.offerObject.shortName}
                                enableClick={false}
                            />
                        )
                        : getAllMarkers()}
                </GoogleMapReact>
            ) : null}
        </Grid>
    );
}

function getLocationsData(state) {
    if (state.filter.isMarkerClicked) return state.filter.markerLocation;
    if (state.filter.searchedText !== "" || state.filter.isFilterApplied)
        return state.filter.filteredLocationData;
    else return state.filter.locationData;
}

const mapStateToProps = state => {
    return {
        locationDetail: state.filter.locationDetail,
        locationData: getLocationsData(state),
        showLocationDetails: state.filter.showLocationDetails,
        groupedLocations: state.filter.groupedLocations,
        isMarkerClicked: state.filter.isMarkerClicked,
        currentLocation: state.filter.currentLocation
    };
};

const mapDispatchToProps = dispatch => ({
    resetMarkerLocationList: () => dispatch(resetMarkerLocationList()),
    resetMobileMarkerLocationList: () => dispatch(resetMobileMarkerLocationList())
});

Map.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(Map)));
