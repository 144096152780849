// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const customTabStyles = makeStyles(theme => ({
    customTab: {
        padding: theme.spacing(1.5),
        textAlign: "center",
        color: theme.palette.text.secondary,
        borderRadius: "0px",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        marginTop: "16px",
        marginBottom: "0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        boxShadow: "none",
        textTransform: "uppercase",
        fontSize: "14px",
        fontFamily: "roboto-medium",
        minWidth: "100px",
        whiteSpace: "nowrap",
    },
    selectedTab: {
        backgroundColor: "rgb(250, 250, 250)",
        color: "#273a66",
        cursor: "default",
    },
    defaultTab: {
        backgroundColor: "#273a66",
        color: "rgb(250, 250, 250)",
        cursor: "pointer",
    }
}));

const mobileTabStyles = makeStyles({
    root: {
        flexGrow: 1,
        boxShadow: "none"
    },
    fullWidthTab: {
        minWidth: "50%",
        backgroundColor: "#273a66"
    },
    selectedTab: {
        minWidth: "50%",
        backgroundColor: "#273a66",
        color: "#ffffff",
        borderBottom: "2px solid rgb(250, 250, 250)",
        fontFamily: "roboto-regular"
    },
    unSelectedTab: {
        minWidth: "50%",
        backgroundColor: "#273a66",
        color: "rgba(250, 250, 250,0.7)",
        fontFamily: "roboto-regular"
    }
});

const tabUIStyles = makeStyles(theme => ({
    root: {
        overflow: "auto",
        maxWidth: "100%",
        backgroundColor: "#f1f1f1",
        paddingTop: "48px"
    },
    tabUi: {
        paddingLeft: "0px",
        paddingRight: "0px",
        maxWidth: "100%",
        backgroundColor: "rgb(250, 250, 250)",
        margin: "10px",

    },
    removeSpaneColor: {
        "&& span": {
            backgroundColor: "transparent"
        }
    }
}));

export {customTabStyles, mobileTabStyles, tabUIStyles}
