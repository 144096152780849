// Libreries
import {connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Container, PropTypes, Tabs, withWidth} from "../../assets/fileImports/MaterialUI"
// Components
import {CustomTab, Facilities, StaticPage} from "../../assets/fileImports/Components"
// CSS
import "../../assets/css/tabUI.css"
// Style
import {tabUIStyles} from "./Style"

function TabUI(props) {
    const classes = tabUIStyles();
    const {t} = props;

    function getTabs() {
        let tabs = [];
        tabs.push(<CustomTab label={t("label_tab_one")} index={0} key="tabs-0"/>);
        for (const pageId in props.pages) {
            if (props.pages.hasOwnProperty(pageId)) {
                const page = props.pages[pageId];
                if (page.menuPosition === "header") {
                    tabs.push(<CustomTab label={t(page.title)} index={pageId} key={"tabs-" + pageId}/>)
                }
            }
        }
        return tabs
    }

    return (
        <Container className={classes.root}>
            <div style={{maxWidth: "100%"}}>
                <Tabs variant="scrollable" scrollButtons="off" value={0}
                      className={(classes.removeSpaneColor + " addMargin")}>
                    {getTabs()}
                </Tabs>
            </div>

            {
                props.mainTabIndex === 0 || props.mainTabIndex === 1
                    ? <Facilities/>
                    : <StaticPage/>
            }
        </Container>
    );
}

TabUI.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
    return {
        mainTabIndex: state.ui.tabIndex,
        pages: state.ui.pagesData
    };
};

export default connect(mapStateToProps)(
    withWidth()(withTranslation("common")(TabUI))
);
