// Action
import {actionType} from "../action";
// Images
import images from "../assets/fileImports/Images"
// conig file
import config from "../config.json"

const initialState = {
    locationFromAPI: {}, // from location API
    filterData: {}, // from filter API

    locationData: [],
    filterArray: [],

    selectedFilters: getInitialFilter(),
    showFilterDialogue: false,
    filterExpandedIndex: null,
    isLanguagesExpanded: false,

    filteredLocationData: [],
    searchedText: "",
    isFilterApplied: false,
    isMarkerClicked: false,
    isMappClicked: false,
    markerLocation: [],
    firstTimeWebOpenedVar: true,
    groupedLocations: [],
    presetFilterName: "",
    presetFilter: getInitialFilter(),

    // Location detail
    showLocationDetails: false,
    locationDetail: {},
    allSelectedFilterString: "",
    locationDetailIdFromURL: "",
    currentLocation: {lat: 0, lng: 0}
};

// for workaround of the bug that Marker in Map calls set and reset marker action together
let isMarkerClickedOnMobile = false;

function getInitialFilter() {
    return {
        offers: [{name: "filterTypeAll", id: 0}],
        features: [{name: "filterTypeAll", id: 0}],
        genders: [{name: "filterTypeAll", id: 0}],
        languages: [{name: "filterTypeAll", id: 0}]
    };
}

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.setLocationFilterData:
            let locationData = getLocationArray(action.locations, action.filter, state.currentLocation);
            let locationDetail = getLocationDetailFromID(state.locationDetailIdFromURL, locationData);
            return {
                ...state,
                locationFromAPI: action.locations,
                locationData: locationData,
                filterData: action.filter,
                filterArray: getFilterArray(action.filter, state.selectedFilters),
                groupedLocations: getGroupedLocations(locationData, action.filter),
                locationDetail: locationDetail,
                showLocationDetails: state.locationDetailIdFromURL !== ""
            };
        case actionType.toggleFilterDialogue:
            return {
                ...state,
                showFilterDialogue: !state.showFilterDialogue,
                filterExpandedIndex: null
            };
        case actionType.setExpandedIndex:
            if (action.index === state.filterExpandedIndex) {
                action.index = null;
            }
            return {
                ...state,
                filterExpandedIndex: action.index
            };
        case actionType.setFilterArray:
            return {
                ...state,
                filterArray: getFilterArray(state.filterData, state.selectedFilters)
            };
        case actionType.setSelectedFilters:
            return {
                ...state,
                selectedFilters: action.filters,
                filterArray: getFilterArray(state.filterData, action.filters)
            };
        case actionType.resetFilter:
            let resetFilteredList = getSearchFilteredList(
                state.locationData,
                state.searchedText,
                getInitialFilter(),
                state.filterData
            );
            return {
                ...state,
                filteredLocationData: resetFilteredList,
                filterExpandedIndex: null,
                selectedFilters: getInitialFilter(),
                showFilterDialogue: false,
                filterArray: getFilterArray(state.filterData, getInitialFilter()),
                groupedLocations: getGroupedLocations(
                    resetFilteredList,
                    state.filterData
                ),
                isFilterApplied: false,
                presetFilterName: "",
                isMarkerClicked: false,
                allSelectedFilterString: ""
            };
        case actionType.searchInLocationList:
            let searchFilteredList = getSearchFilteredList(
                state.locationData,
                action.searchValue,
                state.selectedFilters,
                state.filterData
            );
            return {
                ...state,
                filteredLocationData: searchFilteredList,
                searchedText: action.searchValue,
                groupedLocations: getGroupedLocations(
                    searchFilteredList,
                    state.filterData
                ),
                isMarkerClicked: false
            };
        case actionType.filterLocationList:
            let filteredList = getSearchFilteredList(
                state.locationData,
                state.searchedText,
                action.filters,
                state.filterData
            );
            let filterArray = getFilterArray(state.filterData, action.filters);
            let listElement = document.getElementById(
                "location-list-" + state.locationDetail.id
            );
            if (listElement) listElement.className = "locationListRow";

            let presetFilter = getPresetFilter(state, action);

            return {
                ...state,
                filteredLocationData: filteredList,
                isFilterApplied: checkFilterIsApplied(
                    getInitialFilter(),
                    action.filters
                ),
                selectedFilters: action.filters,
                filterArray: filterArray,
                groupedLocations: getGroupedLocations(filteredList, state.filterData),
                showFilterDialogue: false,
                filterExpandedIndex: null,
                firstTimeWebOpenedVar: false,
                showLocationDetails: false,
                presetFilterName: presetFilter.name,
                presetFilter: presetFilter.filter,
                isMarkerClicked: false,
                allSelectedFilterString: getAllSelectedFiltersString(filterArray)
            };
        case actionType.setMarkerLocationList:
            isMarkerClickedOnMobile = true;
            return {
                ...state,
                markerLocation: action.locations,
                isMarkerClicked: true,
                isMappClicked: false
            };
        case actionType.resetMarkerLocationList:
            let selectedElement = document.getElementById(
                "location-list-" + state.locationDetail.id
            );
            if (selectedElement) selectedElement.className = "locationListRow";
            return {
                ...state,
                isMarkerClicked: false,
                showLocationDetails: false
            };
        case actionType.resetMobileMarkerLocationList:
            if (isMarkerClickedOnMobile === true) {
                isMarkerClickedOnMobile = false;
                return {
                    ...state,
                    ...state
                };
            }
            return {
                ...state,
                isMarkerClicked: false
            };
        case actionType.closeTheWelcomeFilter:
            return {
                ...state,
                firstTimeWebOpenedVar: false
            };
        case actionType.openLocationDetail:
            let showLocationDetails = true;
            if (action.deviceWidth === "sm" || action.deviceWidth === "xs") {
                showLocationDetails = false;
            }
            return {
                ...state,
                locationDetail: action.locationDetail,
                showLocationDetails: showLocationDetails,
            };
        case actionType.closeLocationDetail:
            let element = document.getElementById(
                "location-list-" + state.locationDetail.id
            );
            if (element) element.className = "locationListRow";
            return {
                ...state,
                showLocationDetails: false,
                locationDetail: {},
                locationDetailIdFromURL: ""
            };
        case actionType.setLocationDetailFromUrl:
            return {
                ...state,
                locationDetailIdFromURL: action.id
            };
        case actionType.setCurrentLocation:
            let locationList = [], groupedLocationList = [];
            if (JSON.stringify(state.filterData) !== '{}' && state.locationFromAPI.length !== 0) {
                locationList = getLocationArray(state.locationFromAPI, state.filterData, action.currentLocation);
                groupedLocationList = getGroupedLocations(locationList, state.filterData)
            }

            return {
                ...state,
                currentLocation: action.currentLocation,
                locationData: locationList,
                groupedLocations: groupedLocationList,
            };
        default:
            return {
                ...state,
                ...state
            };
    }
};

function getLocationDetailFromID(id, locations) {
    let locationDetail = {};

    if (id && id !== "") {
        locations.forEach(location => {
            if (location.id.toString() === id) {
                locationDetail = location
            }
        });
    }

    return locationDetail
}

// creates filter for UI
function getFilterArray(filterData, selectedFilters) {
    let languageList = getSortedLanguages(filterData.languages);
    if (
        filterData.constructor === Object &&
        Object.entries(filterData).length !== 0
    ) {
        return [
            {
                filterName: "filterTypeOffer",
                icon: images["home_icon"],
                filterValues: getSingleFilterArray(
                    filterData.offers,
                    selectedFilters.offers
                ),
                selectedFilter: getSelectedFilterString(selectedFilters.offers)
            },
            {
                filterName: "filterTypeFeature",
                icon: images["star_icon"],
                filterValues: getSingleFilterArray(
                    filterData.features,
                    selectedFilters.features
                ),
                selectedFilter: getSelectedFilterString(selectedFilters.features)
            },
            {
                filterName: "filterTypeGender",
                icon: images["filter-gender-all"],
                filterValues: getSingleFilterArrayGender(
                    filterData.genders,
                    selectedFilters.genders
                ),
                selectedFilter: getSelectedFilterString(selectedFilters.genders)
            },
            {
                filterName: "filterTypeLanguage",
                icon: images["comments_icon"],
                filterValues: getSingleFilterArray(
                    languageList,
                    selectedFilters.languages
                ),
                selectedFilter: getSelectedFilterString(selectedFilters.languages)
            }
        ];
    }
}

function getSortedLanguages(languages) {
    languages.sort(function (a, b) {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return languages;
}

function getSingleFilterArray(filter, selectedFilter) {
    let updatedFilter = [];
    filter.forEach((data, index) => {
        let isSelected = false;
        for (let i = 0; i < selectedFilter.length; i++) {
            if (
                selectedFilter[i].name === data.name ||
                selectedFilter[i].name === data.shortName
            ) {
                isSelected = true;
            }
        }
        updatedFilter[index] = {
            name: data.name,
            iconName: data.iconName,
            shortName: data.shortName,
            sortNumber: data.sortNumber,
            id: data.id,
            isSelected: isSelected
        };
    });

    return updatedFilter;
}

function getSingleFilterArrayGender(filter, selectedFilter) {
    let updatedFilter = [];
    filter.forEach((data, index) => {
        if (data.id !== 4) {
            let isSelected = false;
            for (let i = 0; i < selectedFilter.length; i++) {
                if (
                    selectedFilter[i].name === data.name ||
                    selectedFilter[i].name === data.shortName
                ) {
                    isSelected = true;
                }
            }
            updatedFilter[index] = {
                name: data.name,
                iconName: data.iconName,
                shortName: data.shortName,
                sortNumber: data.sortNumber,
                id: data.id,
                isSelected: isSelected
            };
        }
    });

    return updatedFilter;
}

// Selected filter to be shown under the filter type
function getSelectedFilterString(object) {
    let stringArray = "";
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            stringArray += (stringArray === "" ? "" : ", ") + object[key].name;
        }
    }

    return stringArray;
}

function getAllSelectedFiltersString(filterArray) {
    let stringArray = "";
    filterArray.forEach(filter => {
        if (filter.selectedFilter !== "filterTypeAll") {
            stringArray += (stringArray === "" ? "" : ", ") + filter.selectedFilter;
        }
    });
    return stringArray;
}

// Filter location list based on search text
function getSearchFilteredList(
    locationArray = [],
    searchtext = "",
    selectedFilter,
    allFilters
) {
    let searchFilteredLocation = [];
    if (searchtext === "") {
        return getFilteredList(locationArray, selectedFilter, allFilters);
    }

    if (!isNaN(searchtext)) {
        searchFilteredLocation = locationArray.filter(location => {
            return (
                (location.addressPostalCode !== null &&
                    location.addressPostalCode.indexOf(searchtext) !== -1) ||
                (location.contactTelephone !== null &&
                    location.contactTelephone.indexOf(searchtext) !== -1)
            );
        });
    } else {
        searchFilteredLocation = locationArray.filter(location => {
            return (
                compareSearch(location.name, searchtext) ||
                compareSearch(location.providerName, searchtext) ||
                compareSearch(location.contactPerson, searchtext) ||
                compareSearch(location.additionalInformation, searchtext) ||
                compareSearch(location.addressCity, searchtext) ||
                compareSearch(location.addressStreet, searchtext) ||
                compareSearch(location.contactEmail, searchtext) ||
                compareSearch(location.contactWebsite, searchtext) ||
                compareSearch(location.providerLicenseNumber, searchtext) ||
                compareSearch(location.created_at, searchtext) ||
                compareSearch(location.updated_at, searchtext)
            );
        });
    }

    if (checkFilterIsApplied(getInitialFilter(), selectedFilter)) {
        return getFilteredList(searchFilteredLocation, selectedFilter, allFilters);
    } else {
        return searchFilteredLocation;
    }
}

function compareSearch(data, searchtext) {
    return (
        data !== null &&
        data.toLocaleLowerCase().indexOf(searchtext.toLocaleLowerCase()) !== -1
    );
}

// Filter location list based on selected filter
function getFilteredList(locationArray = [], selectedFilter, allFilters) {
    let filteredlocationOffers = locationArray;
    if (selectedFilter.offers.length !== 0 && selectedFilter.offers[0].name !== "filterTypeAll") {
        filteredlocationOffers = locationArray.filter(location => {
            return filterLocation([location.offer], selectedFilter.offers);
        });
    }

    let filteredlocationFeature = filteredlocationOffers;
    if (selectedFilter.features.length !== 0 && selectedFilter.features[0].name !== "filterTypeAll") {
        filteredlocationFeature = filteredlocationOffers.filter(location => {
            return filterLocation(location.features, selectedFilter.features);
        });
    }

    let filteredlocationGender = filteredlocationFeature;
    // Add all filter if all genders are selected
    if (selectedFilter.genders.length === allFilters.genders.length - 1) {
        selectedFilter.genders.push({name: "filterTypeAll", id: 4})
    }

    if (selectedFilter.genders.length !== 0 && selectedFilter.genders[0].name !== "filterTypeAll")
        filteredlocationGender = filteredlocationFeature.filter(location => {
            return filterLocation(location.genders, selectedFilter.genders);
        });

    // Remove all filter from gender after filtering
    if (selectedFilter.genders.length === allFilters.genders.length) {
        selectedFilter.genders.pop()
    }

    let filteredlocationLang = filteredlocationGender;
    if (selectedFilter.languages.length !== 0 && selectedFilter.languages[0].name !== "filterTypeAll")
        filteredlocationLang = filteredlocationGender.filter(location => {
            return filterLocation(location.languages, selectedFilter.languages);
        });

    // Final filtered location array
    return filteredlocationLang;
}

// Filter location array based on parameter
function filterLocation(locationArray, filterObject) {
    for (let i = 0; i < filterObject.length; i++) {
        if (locationArray.includes(filterObject[i].id)) {
            return true;
        }
    }
}

function checkFilterIsApplied(initialFilter, filters) {
    let isFilterApplied = true;
    if (JSON.stringify(initialFilter) === JSON.stringify(filters)) {
        isFilterApplied = false;
    }
    return isFilterApplied;
}

// Create Grouped locations for Markers
export function getGroupedLocations(locationsData, filter) {
    let locations = [...locationsData];
    locations.sort(function (a, b) {
        return a.latLng.lat - b.latLng.lat && a.latLng.lng - b.latLng.lng;
    });

    let groupedLocations = [];
    let tempArray = [];

    for (let i = 0, j = 1; i < locations.length; i++ , j++) {
        if (locations[i].latLng.lat && locations[i].latLng.lng) {
            if (
                locations[i + 1] !== undefined &&
                locations[i].latLng.lat === locations[i + 1].latLng.lat &&
                locations[i].latLng.lng === locations[i + 1].latLng.lng
            ) {
                tempArray.push(locations[i]);
            } else {
                tempArray.push(locations[i]);

                let markerName = j;
                if (j === 1) {
                    for (let k = 0; k < filter.offers.length; k++) {
                        if (locations[i].offer === filter.offers[k].id) {
                            markerName = filter.offers[k].shortName;
                        }
                    }
                }
                groupedLocations = [
                    ...groupedLocations,
                    {markerName: markerName, locations: tempArray}
                ];
                tempArray = [];
                j = 0;
            }
        }
    }

    return groupedLocations;
}

// Get location array with actual gender, feature, language and offer object instead of there ID
function getLocationArray(locations, filter, coordinates) {
    let locationsArray = [];
    let offer = {};

    locations.forEach(location => {
        filter.offers.forEach(filterOffer => {
            if (filterOffer.id === location.offer) {
                offer = filterOffer;
            }
        });

        let locationGenders = getLocationTypeArray(
            filter.genders,
            location.genders
        );
        let locationFeatures = getLocationTypeArray(
            filter.features,
            location.features
        );
        let locationLanguages = getLocationTypeArray(
            filter.languages,
            location.languages
        );

        let distance = "0";
        if (coordinates.lat !== 0 && coordinates.lng !== 0) {
            distance = getDistance(coordinates, location.latLng)
        }

        locationsArray.push({
            ...location,
            distance: distance,
            offerObject: offer,
            genderObjects: locationGenders,
            featureObjects: locationFeatures,
            languageObject: locationLanguages
        });
    });

    return locationsArray;
}

const getDistance = (userLocation, placeLocation) => {
    // const Location1Str = userLocation.lat + "," + userLocation.lng;
    // const Location2Str = placeLocation.lat + "," + placeLocation.lng;
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    // let ApiURL = "https://maps.googleapis.com/maps/api/distancematrix/json?";

    // let params = `origins=${Location1Str}&destinations=${Location2Str}&key=${config.mapApiKey}`; // you need to get a key
    // let finalApiURL = `${proxyurl}${ApiURL}${encodeURI(params)}`;

    // let fetchResult = await fetch(finalApiURL); // call API
    // let Result = await fetchResult.json(); // extract json
    // console.log(Result)
    // return "Result.rows[0].elements[0].distance";

    let R = 6371; // Radius of the earth in km
    let dLat = (placeLocation.lat - userLocation.lat) * Math.PI / 180;  // deg2rad below
    let dLon = (placeLocation.lng - userLocation.lng) * Math.PI / 180;
    let a =
        0.5 - Math.cos(dLat) / 2 +
        Math.cos(userLocation.lat * Math.PI / 180) * Math.cos(placeLocation.lat * Math.PI / 180) *
        (1 - Math.cos(dLon)) / 2;

    let distance = (R * 2 * Math.asin(Math.sqrt(a)));

    return new Intl.NumberFormat(config.language, {maximumFractionDigits: 2}).format(distance)
};

// Get location type(gender, feature, language and offer) array
function getLocationTypeArray(filterTypeArray, locationTypeArray) {
    let newLocationTypeArray = [];
    filterTypeArray.forEach(filterType => {
        locationTypeArray.forEach(locationTypeID => {
            if (filterType.id === locationTypeID) {
                newLocationTypeArray.push(filterType);
            }
        });
    });

    return newLocationTypeArray;
}

function getPresetFilter(state, action) {
    let presetFilter, presetFilterName;
    if (action.presetFilterName) {
        presetFilter = action.filters;
        presetFilterName = action.presetFilterName
    } else {
        if (JSON.stringify(state.presetFilter) === JSON.stringify(action.filters)) {
            presetFilterName = state.presetFilterName;
            presetFilter = state.presetFilter
        } else {
            presetFilterName = "";
            presetFilter = getInitialFilter()
        }
    }

    return {
        name: presetFilterName,
        filter: presetFilter
    }
}

export default filterReducer;
