// Images displayed from API iconName
import AllGender from "../images/filter-gender-all.svg";
import WomenOnly from "../images/filter-gender-women.svg";
import MenOnly from "../images/filter-gender-men.svg";
import ThirdOnly from "../images/filter-gender-diverse.svg";
import Pets from "../images/filter-feature-pets.svg";
import Drugs from "../images/filter-feature-drugs.svg";
import Barrierarm from "../images/filter-feature-barrier-free.svg";
import Family from "../images/filter-feature-family.svg";
// Static image imports
import Home_icon from "../images/icon_custom_location_type_generic_purple.svg";
import Comments_icon from "../images/icon_custom_location_type_consult_black.svg";
import Star_icon from "../images/icon_custom_location_special_generic_purple.svg";
import Location_icon from "../images/location_icon.svg";
import Call_icon from "../images/call_icon.svg";
import Email_icon from "../images/email_icon.svg";
import Earth_icon from "../images/earth_icon.svg";
import InfoIcon from "../../assets/images/info_24_px.svg";
import ExpandMoreIcon from "../../assets/images/expand_more_24_px.svg";
import Filter_icon from "../../assets/images/icon_custom_appbar_filter_white.svg";
import SearchIcon from "../../assets/images/search_24_px.svg";
import Cancel_icon from "../../assets/images/cancel.svg";
import SearchImage from "../../assets/images/search_icon_white.svg";
import FilterImage from "../../assets/images/icon_custom_appbar_filter_black.svg";
import BackIcon from "../../assets/images/arrow_back_24_px.svg";
import BannerApp from "../../assets/images/Banner-App.png"
import MarkerPin from "../../assets/images/bew.svg";

let images = {
    // Used from API response
    "filter-gender-all": AllGender,
    "filter-gender-women": WomenOnly,
    "filter-gender-men": MenOnly,
    "filter-gender-diverse": ThirdOnly,
    "filter-feature-barrier-free": Barrierarm,
    "filter-feature-pets": Pets,
    "filter-feature-drugs": Drugs,
    "filter-feature-family": Family,
    // static images
    "home_icon": Home_icon,
    "comments_icon": Comments_icon,
    "star_icon": Star_icon,
    "location_icon": Location_icon,
    "call_icon": Call_icon,
    "email_icon": Email_icon,
    "earth_icon": Earth_icon,
    "info_icon": InfoIcon,
    "expand_more_icon": ExpandMoreIcon,
    "filter_icon": Filter_icon,
    "search_icon": SearchIcon,
    "cancel_icon": Cancel_icon,
    "search_image": SearchImage,
    "filter_image": FilterImage,
    "back_icon": BackIcon,
    "banner-app": BannerApp,
    "marker-pin": MarkerPin
};

export default images;
