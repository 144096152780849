// Libreries
import {connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Grid, Paper, Typography} from "../../assets/fileImports/MaterialUI"
// Images
import images from "../../assets/fileImports/Images"
// font css
import "../../assets/css/font.css";
// Style
import {noLocationInfoStyle} from "./Style"
// Actions
import {closeLocationDetail} from "../../action";

function NoLocationInfo(props) {
    const {t} = props;
    const classes = noLocationInfoStyle();

    return (
        <div className={classes.parentDivContainer}>
            <div className={classes.childDivInfo}>
                <Grid container spacing={0} className={classes.gridWidth} justify="center" alignItems="center"
                      direction="row">
                    <Grid item xs={12} md={12} lg={12} className={classes.gridWidth}>
                        <Paper className={classes.paperContainer}>
                            <div className={classes.centerNotInfoParagraph}>
                                <img alt="" src={images["cancel_icon"]}
                                     onClick={() => {
                                         props.closeLocationDetail();
                                     }}
                                />
                                <Typography>{t("no_location_info")}</Typography>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        showLocationDetails: false,
        locationDetail: state.filter.locationDetail,
        filterData: state.filter.filterData
    };
};

const mapDispatchToProps = dispatch => ({
    closeLocationDetail: () => dispatch(closeLocationDetail())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(NoLocationInfo));
