// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const locationListViewStyle = makeStyles(theme => ({
    listHight: {
        flex: "1 1 auto",
        overflow: "auto",
        background: "#fff",
        scrollbarWidth: "0px",
        zIndex: "1",
        position: "absolute",
        display: "contents",
        top: "0",
        borderBottomLeftRadius: "20px"
    },
    listModification: {
        "&& span": {
            fontSize: "14px",
            fontFamily: "roboto-regular",
            color: "rgba(0,0,0,0.6)"
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "0px",
        paddingRight: "10px"
    },
    listModificationMultiLine: {
        "&& span": {
            margin: "0px",
            fontSize: "16px",
            fontFamily: "roboto-regular"
        },
        "&& p": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: "0px",
            fontSize: "12px",
            fontFamily: "roboto-regular"
        },
        margin: "0px"
    },
    listModificationMultiLineDistance: {
        "&& span": {
            margin: "0px",
            fontSize: "12px",
            fontFamily: "roboto-regular",
            color: "rgba(0,0,0,0.6)"
        },
        "&& p": {
            color: "transparent",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: "0px",
            fontSize: "12px",
            fontFamily: "roboto-regular"
        },
        margin: "0px",
        textAlign: "right",
        paddingRight: "8px"
    },
    listNoResult: {
        fontSize: "14px",
        fontFamily: "roboto-regular",
        padding: "20px",
        textAlign: "center"
    },
    childGrid: {
        flex: "1 1 auto",
        width: "calc(100%)",
        overflow: "hidden",
        overflowY: "auto",
        background: "#fff",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)"
    },
    listDesktopView: {
        height: "calc(100vh - 212px)",
        borderBottomLeftRadius: "20px"
    },
    listMobileView: {
        minHeight: "calc(100vh - 97px)",
        paddingTop: "60px"
    },
    container: {
        backgroundColor: "#f1f1f1"
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    locationListIcons: {
        minWidth: "22px",
        "&& img": {
            width: "16px"
        }
    },
    desktopColapsible: {
        zIndex: "6",
        overflow: "auto",
        transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "rgb(250, 250, 250)",
        width: "100%"
    },
    buttonStyle: {
        color: "#a31e2d",
        textTransform: "none",
        "&& span": {
            fontFamily: "roboto-medium"
        }
    },
}));

export {locationListViewStyle}
