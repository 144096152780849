// Material UI
import {makeStyles} from "../../assets/fileImports/MaterialUI"

const facilitiesHeaderStyle = makeStyles(theme => ({
    root: {
        overflow: "auto",
        maxWidth: "100%",
        overflowY: "hidden"
    },
    grid: {
        flex: "1 1 auto",
        width: "calc(100%)",
        overflow: "auto"
    },
    avatar: {
        backgroundColor: "transparent",
        color: "#a31e2d",
        borderRadius: "0px",
        position: "relative",
    },
    container: {
        backgroundColor: "#f1f1f1"
    },
    facilitiesHeaderDesktopView: {
        "& li": {
            height: "100%",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
        textAlign: "center",
        borderRadius: "0px",
        marginBottom: "0px",
        height: "72px",
        width: "100%",
        backgroundColor: "rgb(250, 250, 250)"
    },
    searchTab: {
        textAlign: "center",
        borderRadius: "0px",
        marginBottom: "0px",
        height: "72px",
        width: "100%",
        backgroundColor: "rgb(250, 250, 250)"
    },
    searchIcon: {
        minWidth: "30px",
        "&& img": {
            width: "30px",
            height: "30px",
        }
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        padding: "5px",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    facilitiesHeaderMobileView: {
        "& li": {
            height: "100%",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
        textAlign: "center",
        borderRadius: "0px",
        marginBottom: "0px",
        height: "60px",
        width: "100%",
        backgroundColor: "rgb(250, 250, 250)",
    },
    mobileListItemFontSize: {
        "&& span": {
            fontSize: "16px",
            fontFamily: "roboto-regular"
        },
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    listItemFontSize: {
        "& span": {
            fontSize: "16px",
            fontFamily: "roboto-regular"
        },
        "&& p": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontFamily: "roboto-regular"
        }
    },
    redDot: {
        width: "12px",
        height: "12px",
        backgroundColor: "#a31e2d",
        position: "absolute",
        right: "5px",
        top: "5px",
        borderRadius: "50%"
    },
    header: {
        // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        position: "relative",
        zIndex: "5"
    },
    fontSizeFilter: {
        "&& span": {
            fontFamily: "roboto-regular",
            fontSize: "16px"
        },
        "&& p": {
            fontFamily: "roboto-regular",
            fontSize: "14px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    },
    SearchTabClass: {
        "&& input": {
            width: "100%",
            fontFamily: "roboto-regular",
            fontSize: "14px",
        },
        width: "100%",
        height: "30px",
        border: "none",
        background: "transparent",
        outline: "none",
        fontFamily: "roboto-regular",
        fontSize: "14px",
        disableUnderline: true
    },
    searchTabCntainer: {
        borderRadius: "40px",
        border: "1.2px solid #273a66",
        margin: "0px 10px",
        position: "relative",
        top: "12px"
    },
    imageSize: {
        width: "32px",
        height: "32px",
    },
    customIconButton: {
        "&& hover": {
            transition: "none",
            background: "transparent"
        },
        cursor: "pointer",
        padding: "5px",
    },
    cursorClass: {
        cursor: "pointer"
    },
    cancelIcon: {
        cursor: "pointer", color: "gray"
    }
}));

const facilitiesStyle = makeStyles(theme => ({
    facilitiesMobileview: {
        flex: "1 1 auto",
        height: "calc(100%)",
        width: "calc(100%)",
        overflow: "auto",
        maxWidth: "100%",
        overflowY: "hidden",
        paddingTop: "97px"
    },
    facilitiesDesktopView: {
        borderBottomLeftRadius: "10px",
        overflow: "auto",
        maxWidth: "100%",
        overflowY: "hidden"
    },
    desktopGrid: {
        flex: "1 1 auto",
        height: "calc(100%)",
        width: "calc(100%)",
        overflow: "auto",
    },
}));

export {facilitiesHeaderStyle, facilitiesStyle};
