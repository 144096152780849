// Libreries
import {
  BrowserHistory,
  classnames,
  connect,
  React,
  ReactHtmlParser,
  useHistory,
  withTranslation
} from "../../assets/fileImports/Libraries"
// Material UI
import {
  Button,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  PropTypes,
  Typography,
  withWidth
} from "../../assets/fileImports/MaterialUI"
// Components
import {InfoBox} from "../../assets/fileImports/Components"
// Images
import images from "../../assets/fileImports/Images"
// css
import "../../assets/css/locationsListView.css";
import "../../assets/css/font.css";
// Style
import {locationListViewStyle} from "./Style"
// Actions
import {openLocationDetail, resetMarkerLocationList} from "../../action";

function LocationsListView(props) {

    const history = useHistory();
    const classes = locationListViewStyle();
    const {t, width} = props;

    let locations = [];
    if (props.isMarkerClicked && width !== "xs" && width !== "sm") {
        locations = props.markerLocationData;
    } else {
        locations = props.locationData;
    }

    var isEdge = false;
    if ((navigator.userAgent.indexOf("MSIE") !== -1) || (navigator.userAgent.indexOf("Edge") !== -1) || (!!document.documentMode === true)) //IF IE > 10
    {
        isEdge = true;
    }

    const openLocationDetail = locationDetail => {
        var element;

        if (width !== "xs" && width !== "sm") {
            // update class of previously selected row. If any.
            if (props.locationDetail && props.locationDetail.id) {
                element = document.getElementById(
                    "location-list-" + props.locationDetail.id
                );
                if (element) element.className = "locationListRow";
            }

            // Add class for the selected row
            element = document.getElementById("location-list-" + locationDetail.id);
            if (element) element.className = "selectedList";

            BrowserHistory.push("/#/locationDetail/" + locationDetail.id);
        } else {
            history.push("/locationDetail/" + locationDetail.id);
        }

        props.openLocationDetail(locationDetail, width);
    };

    function checkLocationListAvailability() {
        if (locations.length !== 0) {
            return locations.map((location) => {
                return (<div key={"parent-location-list-" + location.id} className={classes.rightSideDevider}>
                        <div
                            className={props.locationDetail.id && props.locationDetail.id === location.id ? "selectedList" : "locationListRow"}
                            key={"location-list-" + location.id}
                            id={"location-list-" + location.id}
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                openLocationDetail(location);
                            }}
                        >
                            <ListItem key={"location-name-" + location.id}>
                                <ListItemText
                                    className={classes.listModificationMultiLine}
                                    primary={location.name}
                                    secondary={location.providerName}
                                />
                                <ListItemText
                                    className={classes.listModificationMultiLineDistance}
                                    primary={location.distance !== "0"
                                        ? location.distance + " " + t("locationDetailsDistanceKilometers")
                                        : t("emptyDistance")}
                                    secondary={"-"}/>
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    className={classes.listModification}
                                    key={"location-service-provider-" + location.id}
                                    primary={location.offerObject.name}
                                />

                                {location.genderObjects.map(function (gender) {
                                    if (gender.id !== 4) {
                                        return (
                                            <ListItemIcon key={"list-gender-icon-" + location.id + "-" + gender.id}
                                                          className={classes.locationListIcons}>
                                                <img src={images[gender.iconName]} alt=""/>
                                            </ListItemIcon>
                                        );
                                    }
                                    return null;
                                })}

                                {location.featureObjects.map(function (locationFeature) {
                                    return (
                                        <ListItemIcon
                                            key={"list-feature-icon-" + location.id + "-" + locationFeature.id}
                                            className={classes.locationListIcons}
                                        >
                                            <img src={images[locationFeature.iconName]} alt=""/>
                                        </ListItemIcon>
                                    );
                                })}
                            </ListItem>
                        </div>
                        <Divider/>
                    </div>
                )
            });
        } else {
            return <div key={"parent-location-list-"} className={classes.rightSideDevider}>
                <div className={"locationListRow"}>
                    <Typography className={classes.listNoResult}>
                        {ReactHtmlParser(t("listNoResult"))}
                    </Typography>
                </div>
            </div>
        }
    }

    return (
        <Grid item sm={12} xs={12} md={4} lg={4}
              className={classnames({
                  [classes.childGrid]: true,
                  [classes.listDesktopView]: width !== "sm" && width !== "xs",
                  [classes.listMobileView]: width === "sm" || width === "xs"
              })}
        >
            <Grid>
                <div className={(classes.desktopColapsible)}>
                    <Collapse in={props.showInfoBox} timeout="auto" unmountOnExit>
                        <InfoBox/>
                    </Collapse>
                </div>
            </Grid>
            <Grid>
                <List className={classes.listHight} component="div"
                      style={isEdge ? (width === "sm" || width === "xs") ? {
                          width: "100%",
                          height: "100%",
                          marginTop: "158px"
                      } : {width: "33.34%", height: "calc(100vh - 228px)"} : null}>
                    {checkLocationListAvailability()}
                    {props.isMarkerClicked && <Button
                        variant="text"
                        fullWidth
                        onClick={() => {
                            props.resetMarkerLocationList()
                        }}
                        className={classes.buttonStyle}
                    >
                        {t("back_to_search")}
                    </Button>}
                </List>
            </Grid>
        </Grid>
    );
}

function getLocationsData(state) {
    if (state.filter.searchedText !== "" || state.filter.isFilterApplied)
        return state.filter.filteredLocationData;
    else
        return state.filter.locationData;
}

const mapStateToProps = state => {
    return {
        locationDetail: state.filter.locationDetail,
        filterData: state.filter.filterData,
        showInfoBox: state.ui.showInfoBox,
        locationData: getLocationsData(state),
        markerLocationData: state.filter.markerLocation,
        isMarkerClicked: state.filter.isMarkerClicked,
        currentLocation: state.filter.currentLocation
    };
};

const mapDispatchToProps = dispatch => ({
    openLocationDetail: (locationDetail, width) => dispatch(openLocationDetail(locationDetail, width)),
    resetMarkerLocationList: () => dispatch(resetMarkerLocationList()),
});

LocationsListView.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(LocationsListView)));
