// Libreries
import {BrowserHistory, connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  PropTypes,
  Typography,
  withWidth
} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
import "../../assets/css/locationDetail.css"
// Images
import images from "../../assets/fileImports/Images"
// Actions
import {closeLocationDetail} from "../../action";
// styles
import {detailDialogueStyles} from "./Style"
// Components
import {NoLocationInfo} from "../../assets/fileImports/Components"

function LocationDetailDialogue(props) {
    const {t, width} = props;
    const classes = detailDialogueStyles();

    function closeDialogue() {
        if (BrowserHistory.location.hash.indexOf("locationDetail") !== -1) {
            BrowserHistory.replace("/#/")
        }
        props.closeLocationDetail();
    }

    if (shouldShowNoDataMSG(props.locationDetail)) {
        return <NoLocationInfo/>
    } else {
        return (
            <div className={classes.parentDivContainer}>
                <div className={classes.childDivInfo}>
                    <Grid container spacing={0} className={classes.gridWidth}>
                        <Grid item xs={12} md={12} lg={12} className={classes.gridWidth}>
                            <Paper className={classes.paperContainerMain}
                                   style={width === "xl" ? {maxHeight: "auto"} : {maxHeight: "400px"}}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={7} lg={7} container spacing={0}>
                                        <Paper className={classes.paperContainerChild}>
                                            <Typography variant="h5" component="h5" className={classes.typoH5}>
                                                {props.locationDetail.name}
                                            </Typography>
                                            <Typography variant="subtitle2" className={classes.typoH6}>
                                                {props.locationDetail.providerName}
                                            </Typography>
                                            {props.locationDetail.offer ? <ListItem className={classes.liPadding}>
                                                <ListItemIcon className={classes.listIcons}>
                                                    <img src={images["home_icon"]} alt=""/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    key={"details-offer-name-" + props.locationDetail.id}
                                                    className={classes.fontFamilyForSpan}
                                                    primary={props.locationDetail.offerObject.name + " (" + props.locationDetail.offerObject.shortName + ")"}
                                                />
                                            </ListItem> : null}

                                            {checkDataAvailability(props.locationDetail) ? null : (
                                                <Typography variant="subtitle2" className={classes.locInfoTitleTwo}>
                                                    {t("locationDetailsSectionFeaturesTitle")}
                                                </Typography>
                                            )}
                                            <List component="nav" aria-label="secondary mailbox folders"
                                                  key="general-detail" style={{paddingBottom: "0"}}>

                                                {/* Gender */}
                                                {props.locationDetail.genderObjects.map(function (gender, index) {
                                                    return (getDetailOfListArray(classes, "detail-gender-" + index, gender));
                                                })}

                                                {/* Features */}
                                                {props.locationDetail.featureObjects.map(function (feature, index) {
                                                    return (getDetailOfListArray(classes, "detail-feature-" + index, feature));
                                                })}

                                                {/* Language */}
                                                {props.locationDetail.languageObject.map(function (language, index) {
                                                    return (getDetailOfListArray(classes, "detail-language-" + index, language, index));
                                                })}

                                                {/* Addtional info */}
                                                {!isEmpty(props.locationDetail.additionalInformation)
                                                    ? (getDetailOfListArray(classes, "detail-info-" + props.locationDetail.id, {
                                                        name: props.locationDetail.additionalInformation,
                                                        iconName: "star_icon"
                                                    })) : null}
                                            </List>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={5}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <img
                                                    alt=""
                                                    src={images["cancel_icon"]}
                                                    className={classes.calcelCustomImage}
                                                    onClick={closeDialogue}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper className={(classes.paper, classes.contactParentDivePaper)}>
                                                    <List key="contact-info">
                                                        {addressBlock(
                                                            classes,
                                                            images["location_icon"],
                                                            t("locationDetailsSectionContactAddress"),
                                                            {
                                                                addressStreet: props.locationDetail.addressStreet,
                                                                addressCity: props.locationDetail.addressCity,
                                                                addressPostalCode: props.locationDetail.addressPostalCode
                                                            },
                                                            props.locationDetail.latLng,
                                                            openGoogleMap
                                                        )}

                                                        {getContactBlocks(
                                                            classes,
                                                            images["call_icon"],
                                                            t("locationDetailsSectionContactPhone"),
                                                            props.locationDetail.contactTelephone
                                                        )}

                                                        {getContactBlocks(
                                                            classes,
                                                            images["email_icon"],
                                                            t("locationDetailsSectionContactEmail"),
                                                            props.locationDetail.contactEmail,
                                                            value => {
                                                                window.location.href = `mailto:${value}?subject=${t("locationDetailsContactEmailDefaultSubject")}&body=${t("locationDetailsContactEmailDefaultBody")}`;
                                                            }
                                                        )}

                                                        {getContactBlocks(
                                                            classes,
                                                            images["earth_icon"],
                                                            t("locationDetailsSectionContactWebsite"),
                                                            props.locationDetail.contactWebsite,
                                                            openWebSite
                                                        )}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

function getDetailOfListArray(classes, listItmeKey, data, index) {

    return <ListItem className={classes.liPadding} key={listItmeKey}>
        <ListItemIcon className={classes.listIcons}>

            {index !== undefined ?
                (< img src={index === 0 ? images["comments_icon"] : ""} alt=""/>) :
                (<img src={images[data.iconName]} alt=""/>)}
        </ListItemIcon>
        <ListItemText
            className={classes.fontFamilyForSpan}
            primary={data.name}
        />
    </ListItem>
}

function checkDataAvailability(locationDetail) {
    return (
        locationDetail.languages.length === 0 &&
        locationDetail.features.length === 0 &&
        locationDetail.genders.length === 0 &&
        isEmpty(locationDetail.additionalInformation)
    );
}

function openGoogleMap(lat, lng) {
    let coordinates = lat + "," + lng;
    let url = "https://www.google.com/maps/search/?api=1&query=" + coordinates;
    window.open(url, "_blank");
}

function openWebSite(value) {
    window.open(value, "_blank");
}

function addressBlock(classes, icon, title, addressObj, location, callback) {
    if (
        isEmpty(addressObj.addressStreet) &&
        isEmpty(addressObj.addressPostalCode) &&
        isEmpty(addressObj.addressCity)
    ) {
        return null;
    } else {
        return (
            <ListItem
                key={"detail-contact-block-" + title}
                button={callback !== undefined ? true : false}
                onClick={() => {
                    if (callback !== undefined) callback(location.lat, location.lng);
                }}
                className={classes.contactBoxShadow}
            >
                <ListItemIcon className={classes.contactBlockIcons}>
                    <img src={icon} alt=""/>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                    }
                    secondary={<span>{addressObj.addressStreet}{addressObj.addressStreet &&
                    <br/>}{addressObj.addressPostalCode} {addressObj.addressCity}</span>}
                />
            </ListItem>
        );
    }
}

function getContactBlocks(classes, icon, title, value, callback) {
    if (isEmpty(value)) {
        return null;
    } else {
        return (
            <ListItem
                key={"detail-contact-block-" + title}
                button={callback !== undefined ? true : false}
                onClick={() => {
                    if (callback !== undefined) callback(value);
                }}
                className={(classes.contactBoxShadow + " contact-block")}
            >
                <ListItemIcon className={classes.contactBlockIcons}>
                    <img src={icon} alt=""/>
                </ListItemIcon>
                <ListItemText
                    className={classes.wrapText}
                    primary={
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                    }
                    secondary={value}
                />
            </ListItem>
        );
    }
}

function shouldShowNoDataMSG(location) {
    return (
        !location.genders.length &&
        !location.features.length &&
        !location.languages.length &&
        isEmpty(location.additionalInformation) &&
        isEmpty(location.contactTelephone) &&
        isEmpty(location.contactEmail) &&
        isEmpty(location.contactWebsite) &&
        isEmpty(location.addressStreet) &&
        isEmpty(location.addressPostalCode) &&
        isEmpty(location.addressCity)
    );
}

function isEmpty(str) {
    return (!str || 0 === str.trim().length);
}

const mapStateToProps = state => {
    return {
        showLocationDetails: false,
        locationDetail: state.filter.locationDetail,
        filterData: state.filter.filterData
    };
};

const mapDispatchToProps = dispatch => ({
    closeLocationDetail: () => dispatch(closeLocationDetail())
});

LocationDetailDialogue.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withTranslation("common")(LocationDetailDialogue)));
