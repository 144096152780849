// Libreries
import {classnames, connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Grid, Paper} from "../../assets/fileImports/MaterialUI"
// font css
import "../../assets/css/font.css";
// Style
import {customTabStyles} from "./Style"
// Action
import {changeMainTab} from "../../action";

function CustomTab(props) {
    const classes = customTabStyles();

    return (
        <Grid item xs>
            <Paper
                className={classnames({
                    [classes.customTab]: true,
                    [classes.selectedTab]: props.mainTabIndex === props.index,
                    [classes.defaultTab]: props.mainTabIndex !== props.index
                })}
                onClick={() => {
                    props.changeMainTab(props.index);
                }}
            >
                {props.label}
            </Paper>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        mainTabIndex: state.ui.tabIndex
    };
};

const mapDispatchToProps = dispatch => ({
    changeMainTab: id => dispatch(changeMainTab(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(CustomTab));
