// Libreries
import {connect, React, withTranslation} from "../../assets/fileImports/Libraries"
// Material UI
import {Grid, PropTypes, withWidth} from "../../assets/fileImports/MaterialUI"
// Components
import {FacilitiesHeader, LocationsListView, Map} from "../../assets/fileImports/Components"
// CSS
import "../../assets/css/facilities.css";
// styles
import {facilitiesStyle} from "./Style"

function Facilities(props) {
    const classes = facilitiesStyle();
    const {width} = props;

    if (width !== "xs" && width !== "sm") {
        return <div className={classes.facilitiesDesktopView}>
            <FacilitiesHeader/>
            <Grid container spacing={0} className={classes.desktopGrid}>
                <LocationsListView/>
                <Map/>
            </Grid>
        </div>
    } else {
        return <Grid container spacing={0} className={classes.facilitiesMobileview}>
            {props.mainTabIndex === 0 ? <LocationsListView/> : null}
            {props.mainTabIndex === 1 ? <Map/> : null}
        </Grid>
    }
}

const mapStateToProps = state => {
    return {
        mainTabIndex: state.ui.tabIndex
    };
};

Facilities.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

export default connect(
    mapStateToProps
)(withWidth()(withTranslation("common")(Facilities)));
